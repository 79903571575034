import React, { CSSProperties } from 'react'
import { QuickTableHeaders, QuickTable } from 'react-ui-scaffold'
import DeleteCell from '../../components/QuickTableCells/DeleteCell'
import useClients from '../../data-hooks/useClients'
import { Client } from '../../../../commonTypes/ClientTypes'
import CurrencyCell from '../../components/QuickTableCells/CurrencyCell'
import RedCurrencyCell from '../../components/QuickTableCells/RedCurrencyCell'
import linkCell from '../../components/QuickTableCells/linkCell'
import { createClientPath } from '../../paths'

const iconStyler = (): CSSProperties => ({ textAlign: 'center' })

interface Props {
	clients: Client[],
	loading?: boolean
}


export default function ClientsTable({ clients, loading }: Props) {

	const { del } = useClients()

	const headers: QuickTableHeaders = {
		name: { title: 'Name', width: 250, headerStyle: { textAlign: 'left' }, component: linkCell, props: {
			createUrl: (_cell:any, row:any) => createClientPath(row._id),
		} },
		defaultRate: { title: 'Rate ($/hr)', component:CurrencyCell, width: 110 },
		pendingAmount: { title: 'Outstanding', component: RedCurrencyCell, width: 150 },
		totalPreTaxExpenseAmount:{ title: 'Expenses', component: CurrencyCell, width: 150 },
		totalTimeAmount:{ title: 'Time (In)', component: CurrencyCell, width: 150 },
		totalPreTaxInvoicedAmount:{ title: 'Invoiced', component: CurrencyCell, width: 150 },
		totalCredits:{ title: 'Credits', component: CurrencyCell, width: 150 },
		delete: {
			title: '',
			width: 100,
			component: DeleteCell,
			cellStyle: iconStyler,
			props: {
				onDelete: del,
			},
		},
	}

	if ((clients.length === 0) && (!loading)) {
		return <div className="ChargeTable">
			<div className='noCharges'>
				You have no clients to display
			</div>
		</div>
	}

	return (
		<div className="ChargeTable ChargeCodeTable">
			<QuickTable
				data={clients}
				headers={headers}
				primaryKey={'_id'}
				hover
				headerStyle={{ textAlign: 'center' }}
			/>
		</div>
	)
}

