import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'

export default function ClientCell({ cell }:QuickTableCellProps) {
	return (
		<span>
			{cell?.name}
		</span>
	)
}
