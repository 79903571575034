import { faPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { MenuButton, QuickTableCellProps } from 'react-ui-scaffold'
import { ChargeCode, ChargeCodeMember } from '../../../../../commonTypes/ChargeCodeTypes'
import AddMemberPopup from './AddMemberPopup'
import MemberCard from './MemberCard'
import './MembersCell.css'

interface Props{
	members: ChargeCodeMember[],
	chargeCode: ChargeCode
}

function MembersMenu({ members, chargeCode }:Props){
	const [adding, setAdding] = useState(false)
	
	return <>
		<AddMemberPopup visible={adding} setVisible={setAdding} chargeCode={chargeCode}/>
		<div className='MembersMenu'>
			{members.map(m => <MemberCard key={m._id} member={m} chargeCode={chargeCode}/>)}
			{!adding && <button className="button wide addMember" onClick={() => setAdding(true)}>
				<FontAwesomeIcon icon={faPlus} /> Add Member
			</button>}
		</div>
	</>
}

export default function MembersCell({ cell, row }:QuickTableCellProps) {
	return (
		<MenuButton
			className='cellButton MembersCell'
			menuContent={MembersMenu}
			style={{ color: cell?.length ? 'var(--neutralAccentColor)' : '' }}
			menuProps={{
				members: cell,
				chargeCode: row,
			}}
		>
			<FontAwesomeIcon
				icon={faUsers}
			/> {cell ? cell.length : ''}
		</MenuButton>
	)
}
