import React, { useState } from 'react'
import { Page, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import AsyncButton from '../../components/AsyncButton'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useBankConnections, { BankConnectionsProvider } from '../../data-hooks/useBankConnections'
import wrapWithProvider from '../../utils/wrapWithProvider'
import CreateBankConnectionPopup from './CreateBankConnectionPopup'
import ReconnectCell from './ReconnectCell'

function BankConnectionsPage() {
	const [showCreatePopup, setShowCreatePopup] = useState(false)
	const [linkToken, setLinkToken] = useState('')
	const { getLinkToken, isLoading, bankConnections } = useBankConnections()
	
	const createHandler = async () => {
		const token = await getLinkToken()
		setLinkToken(token)
		setShowCreatePopup(true)
	}
	
	const headers:QuickTableHeaders= {
		name: { title: 'Name', width: 200 },
		bankAccounts: { title: 'Accounts', component: ({ cell }) => <>{`${cell.length} accounts`}</>, width: 110 },
		reconnect: { title: '', component: ReconnectCell },
	}
	
	return (
		<div className="flex">
			{linkToken && <CreateBankConnectionPopup
				visible={showCreatePopup}
				setVisible={setShowCreatePopup}
				linkToken={linkToken}
				key={`${linkToken}${showCreatePopup}`} //reset the popup's internal state any time 'linktoken' or 'visible' change
			/>}
			
			<Sidebar/>
			<NotSidebar>
				<NavBar title='Bank Connections'>
					<AsyncButton className="button" onClick={createHandler}>Create</AsyncButton>
				</NavBar>
				
				<Page loading={isLoading}>
					<QuickTable
						headers={headers}
						data={bankConnections}
						primaryKey={'_id'}
						hover
					/>
				</Page>
			</NotSidebar>
		</div>
	)
}

export default wrapWithProvider(BankConnectionsPage, BankConnectionsProvider)
