import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import AsyncButton from '../AsyncButton'

export default function SyncCell({ row, sync }:QuickTableCellProps) {
	return (
		<AsyncButton className='plain'
			style={{
				opacity: '40%',
			}}
			onClick={() => sync(row.stripeId)}
		>
			<FontAwesomeIcon icon={faSync}/>
		</AsyncButton>
	)
}
