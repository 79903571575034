import React, { CSSProperties } from 'react'
import { Page, MenuButton, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import NavBar from '../../components/NavBar/NavBar'
import CurrencyCell from '../../components/QuickTableCells/CurrencyCell'
import DeleteCell from '../../components/QuickTableCells/DeleteCell'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useSalesTaxPayments, { SalesTaxPaymentsProvider } from '../../data-hooks/useSalesTaxPayments'
import wrapWithProvider from '../../utils/wrapWithProvider'
import CreateSalesTaxPaymentMenu from './CreateSalesTaxPaymentMenu'

const TYPE_MAP:any = {
	tax_payment: 'Tax Payment',
	tax_return: 'Tax Return',
}

const iconStyler = (): CSSProperties => ({ textAlign: 'center' })

function SalesTaxPaymentsPage() {
	const { isLoading, salesTaxPayments, del } = useSalesTaxPayments()
	
	const headers:QuickTableHeaders = {
		reportingYear: { title: 'Year' },
		amount: { title: 'Amount', width: 220, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		type: { title: 'Type', width: 200, component: ({ cell }) => TYPE_MAP[cell] || cell },
		delete: {
			title: '',
			width: 100,
			component: DeleteCell,
			cellStyle: iconStyler,
			props: {
				onDelete: del,
			},
		},
		
	}
	
	return (
		<div className="flex SalesTaxPayments">
			<Sidebar/>
			<NotSidebar>
				<Page loading={isLoading}>
					<NavBar title='Sales Tax Payments'>
						<MenuButton
							className='button'
							permanent
							menuContent={CreateSalesTaxPaymentMenu}
						>Create</MenuButton>
					</NavBar>
					
					<QuickTable
						data={salesTaxPayments}
						headers={headers}
						primaryKey='_id'
						hover
					/>
				</Page>
			</NotSidebar>
		</div>
	)
}

export default wrapWithProvider(SalesTaxPaymentsPage, SalesTaxPaymentsProvider)
