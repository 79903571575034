import React from 'react'
import { QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { Invoice } from '../../../../commonTypes/InvoiceTypes'
import { createClientPath } from '../../paths'
import CurrencyCell from '../QuickTableCells/CurrencyCell'
import DeleteCell from '../QuickTableCells/DeleteCell'
import LinkCell from '../QuickTableCells/linkCell'
import StatusCell from './StatusCell'
import './InvoiceTable.css'
import SyncCell from './SyncCell'

interface Props {
	invoices: Invoice[],
	onDelete?(id: string): Promise<void>,
	showClient?: boolean,
	onSyncInvoice(stripeId:string):Promise<void>
}

export default function InvoiceTable({ invoices, onDelete, showClient, onSyncInvoice }: Props) {
	
	let headers: QuickTableHeaders = {
		reportingMonth: { title: 'Month', width: 150, headerStyle: { textAlign: 'left' }, component: LinkCell, props: {
			createUrl: (_cell: any, row: Invoice) => `https://dashboard.stripe.com/invoices/${row.stripeId}`,
		} },
		status: { title: 'Status', width: 150, props: { style: { textAlign: 'left' } }, component: StatusCell },
		sync: { title: '', width: 100, component: SyncCell, props: { sync: onSyncInvoice } },
		subtotal: { title: 'Subtotal', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		tax: { title: 'Tax', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		total: { title: 'Total', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
	}
	
	if (showClient){
		headers = {
			client: { title: 'Client', width: 200, headerStyle: { textAlign: 'left' }, component: LinkCell, props: {
				createUrl: (_cell:any, row:any) => createClientPath(row.client?._id),
				createLinkText: (_cell:any, row:any) => row.client?.name || '',
			} },
			...headers,
		}
	}
	
	if (onDelete){
		headers.del = { title: '', width: 40, component: DeleteCell, props: { onDelete } }
	}

	return (
		<div className='InvoiceTable'>
			<QuickTable
				headers={headers}
				data={invoices}
				primaryKey='_id'
				hover
			/>
		</div>
	)
}
