import React, { useCallback } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { createCharge, delCharge, getAllCharges } from '../api/charges'
import { Charge, ChargeFilters, CreateChargeParams } from '../../../commonTypes/ChargeTypes'
import useAuth from './useAuth'
import useOrganization from './useOrganization'


const AllChargesContext = createContext<{
	charges: Charge[],
	isLoading: boolean,
	create(c: CreateChargeParams): Promise<void>,
	del(id: string): Promise<any>,
}>(null as any)

export function AllChargesProvider({ children, filters }: {children: any, filters: ChargeFilters}) {
	const [charges, setCharges] = useState<Charge[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { account } = useAuth()
	const { organization } = useOrganization()

	useEffect(() => {
		getAllCharges({
			organizationId: organization?._id as string,
			filters,
		}).then((c: Charge[]) => {
			setIsLoading(false)
			setCharges(c)
		})
	}, [account, organization])
	
	useEffect(() => {
		getAllCharges({
			organizationId: organization?._id as string,
			filters,
		}).then(setCharges)
	}, [filters])

	const create = useCallback(async (c: CreateChargeParams) => {
		await createCharge(c)
		await getAllCharges({
			organizationId: organization?._id as string,
			filters,
		}).then(setCharges)
	}, [])

	const del = useCallback(async (chargeId: string) => {
		await delCharge({ chargeId })
		await getAllCharges({
			organizationId: organization?._id as string,
			filters,
		}).then(setCharges)
	}, [])

	return <AllChargesContext.Provider value={{
		charges,
		isLoading,
		create,
		del,
	}}>
		{children}
	</AllChargesContext.Provider>
}

export default function useAllCharges() {
	const val = useContext(AllChargesContext)
	if (!val) {
		throw new Error('useAllCharges outside provider!')
	}
	return val
}
