import React, { useState } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import { AddOrganizationMemberParams } from '../../../../commonTypes/OrganizationTypes'
import { ACCESS_LEVELS } from '../../api/employees'
import useEmployees from '../../data-hooks/useEmployees'

export default function AddEmployeeMenu() {
	const [formData, setFormData] = useState<AddOrganizationMemberParams>({
		defaultRate: 0,
		email: '',
		firstName: '',
		lastName: '',
		accessLevel: 'member',
	})

	const [loading, setLoading] = useState(false)
	const { add } = useEmployees()

	function changeHandler(e: any) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}

	const createHandler = async () => {
		if (formData.email && formData.firstName && formData.lastName) {
			setLoading(true)
			await add({
				...formData,
				defaultRate: formData.defaultRate * 100,
			}).catch(() => {
				setLoading(false)
			})
			setLoading(false)
		}
	}

	return (
		<div className='CreateChargeMenu formMenu'>
			<div className="story">
				<label>First Name</label>
				<input
					className="input wide"
					name='firstName'
					value={formData.firstName}
					onChange={changeHandler}
				/>
			</div>
			
			<div className="story">
				<label>Last Name</label>
				<input
					className="input wide"
					name='lastName'
					value={formData.lastName}
					onChange={changeHandler}
				/>
			</div>
			
			<div className="story">
				<label>Email</label>
				<input
					className="input wide"
					name='email'
					value={formData.email}
					onChange={changeHandler}
				/>
			</div>
			
			<div className="story">
				<label>Rate ($/hr)</label>
				<input
					className="input wide"
					type={'number'}
					name='defaultRate'
					value={formData.defaultRate}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label>Access Level</label>
				<select
					className="input wide"
					name='accessLevel'
					value={formData.accessLevel}
					onChange={changeHandler}
				>
					{ACCESS_LEVELS.map((c: any) => <option value={c} key={c}>
						{c}
					</option>)}
				</select>
			</div>

			<div className="story">
				<FreeButton
					className="button positive"
					style={{ width: '100%' }}
					onClick={createHandler}
					loading={loading}
				>
					Create
				</FreeButton>
			</div>

		</div>
	)
}
