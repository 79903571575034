import { BankTransaction } from '../../../commonTypes/BankingTypes'
import { Organization, OrganizationSummary } from '../../../commonTypes/OrganizationTypes'
import beam from './beam'

export const getOrganization = (id:string) => beam.get<Organization>(`/api/organizations/${id}`)

export const getOrganizationSummary = (organizationId:string) => beam.get<OrganizationSummary>(`/api/organizations/${organizationId}/summary`)

export const syncOrganizationAccountValue = (organizationId:string) => beam.patch<number>(`/api/organizations/${organizationId}/totalInAccount`)

export const syncOrganizationBankTransactions = (organizationId: string) => beam.post<BankTransaction>(`/api/organizations/${organizationId}/bankTransactions`)
