import React, { CSSProperties } from 'react'
import { QuickTableHeaders, QuickTable } from 'react-ui-scaffold'
import DeleteCell from '../QuickTableCells/DeleteCell'
import useAllChargeCodes from '../../data-hooks/useAllChargeCodes'
import { ChargeCodeSummary } from '../../../../commonTypes/ChargeCodeTypes'
import ClientCell from './ClientCell'
import RedCurrencyCell from '../QuickTableCells/RedCurrencyCell'
import CurrencyCell from '../QuickTableCells/CurrencyCell'
import MembersCell from './MembersCell/MembersCell'

const iconStyler = ():CSSProperties => ({ textAlign: 'center' })

interface Props{
	chargeCodes: ChargeCodeSummary[],
	loading?:boolean
}


export default function ChargeCodeTable({ chargeCodes, loading }: Props) {
	
	const { del } = useAllChargeCodes()
	
	const headers: QuickTableHeaders = {
		name: { title: 'Name', width: 200 },
		client: { title: 'Client', width: 200, component: ClientCell },
		members: { title: '', component: MembersCell },
		totalOutstandingAmount: { title: 'Outstanding', component: RedCurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalTimeCashflowIn: { title: 'Time (In)', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalTimeCashflowOut: { title: 'Time (Out)', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalPreTaxExpenses: { title: 'Expenses', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalCreditAmmount: { title: 'Credits', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		profit: { title: 'Profit', width: 120, component: CurrencyCell, props: { style: { textAlign: 'left' } } },
		delete: {
			title: '',
			width: 100,
			component: DeleteCell,
			cellStyle: iconStyler,
			props: {
				onDelete: del,
			},
		},
	}
	
	if ((chargeCodes.length === 0) && (!loading)) {
		return <div className="ChargeTable">
			<div className='noCharges'>
				You have no charge codes to display
			</div>
		</div>
	}

	return (
		<div className="ChargeTable ChargeCodeTable">
			<QuickTable
				data={chargeCodes}
				headers={headers}
				primaryKey={'_id'}
				hover
			/>
		</div>
	)
}

