import dayjs from 'dayjs'
import React, {  useState } from 'react'
import useAuth from '../../data-hooks/useAuth'
import useMyChargeCodes from '../../data-hooks/useMyChargeCodes'
import useMyCharges from '../../data-hooks/useMyCharges'
import { ChargeCode } from '../../../../commonTypes/ChargeCodeTypes'
import { getReportingMonth } from '../../utils/dateUtils'
import AsyncButton from '../../components/AsyncButton'
import { CreateChargeParams } from '../../../../commonTypes/ChargeTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const CURRENT_MONTH = getReportingMonth()
const NEXT_MONTH = getReportingMonth(dayjs().add(1, 'month').toDate())
const PREV_MONTH = getReportingMonth(dayjs().add(-1, 'month').toDate())

export default function CreateChargeMenu() {
	const [formData, setFormData] = useState<Partial<CreateChargeParams>>({
		reportingMonth: PREV_MONTH,
		chargeCodeId: '',
		hours: 0,
		notes: '',
		unpaid: false,
	})

	function changeHandler(e:any) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}
	const { chargeCodes } = useMyChargeCodes()
	const { create } = useMyCharges()
	const { account } = useAuth()
	
	const [message, setMessage] = useState('')
	
	const createHandler = async () => {
		if (formData.reportingMonth && formData.hours && formData.chargeCodeId){
			await create({
				...formData,
				userId: account._id,
			} as CreateChargeParams)
			
			setMessage('')
		} else {
			setMessage('Fill all required fields!')
		}
	}
	
	return (
		<div className='CreateChargeMenu formMenu'>
			<div className='story' style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}>
				{message}
			</div>
			
			<div className="story">
				<label>Hours</label>
				<input
					type="number"
					className="input wide"
					name='hours'
					value={formData.hours}
					onChange={changeHandler}
					min='0'
				/>
			</div>

			<div className="story">
				<label>Charge Code</label>
				<select
					className="input wide"
					name='chargeCodeId'
					value={formData.chargeCodeId}
					onChange={changeHandler}
				>
					<option value=''></option>
					{chargeCodes.map((c:ChargeCode) => <option value={c._id} key={c._id}>
						{c.name}
					</option>)}
				</select>
			</div>
			
			<div className="story">
				<label>Reporting Month</label>
				<select
					className='wide input'
					name='reportingMonth'
					value={formData.reportingMonth}
					onChange={changeHandler}
				>
					<option value={PREV_MONTH}>{PREV_MONTH}</option>
					<option value={CURRENT_MONTH}>{CURRENT_MONTH}</option>
					<option value={NEXT_MONTH}>{NEXT_MONTH}</option>
				</select>
			</div>
			
			<div className="story">
				<input
					type="checkbox"
					className="input"
					name='unpaid'
					checked={formData.unpaid}
					onChange={({ target }) => setFormData({ ...formData, unpaid: target.checked })}
				/>
				<label
					style={{
						color: formData.unpaid ? 'var(--negativeAccentColor)' : '',
						display: 'inline',
					}}
				>I do not want to be paid for these hours</label>
			</div>

			<div className="story">
				<label>Notes</label>
				<textarea
					className="input wide"
					name='notes'
					value={formData.notes}
					onChange={changeHandler}
					style={{ resize: 'vertical' }}
				/>
			</div>

			<div className="story">
				<AsyncButton
					className="button positive"
					style={{ width: '100%' }}
					onClick={createHandler}
				>
					{formData.unpaid && <FontAwesomeIcon icon={faExclamationTriangle}/>} Create
				</AsyncButton>
			</div>

		</div>
	)
}
