import React, { useContext, createContext, useEffect, useState } from 'react'
import { BankConnectionSummary, CreateBankConnectionParams } from '../../../commonTypes/BankingTypes'
import { addAccountsToBankConnection, createBankConnection, createLinkToken, getOrganizationBankConnections } from '../api/bankConnections'
import useOrganization from './useOrganization'

const Context = createContext<{
	getLinkToken(): Promise<string>,
	create(params: Omit<CreateBankConnectionParams, 'organizationId'>): Promise<BankConnectionSummary>,
	bankConnections: BankConnectionSummary[],
	isLoading: boolean,
	addAccounts(bankConnectionId:string, plaidAccountIds:string[]): Promise<BankConnectionSummary>
}>(null as any)


interface ProviderProps{
	children:any
}

export const BankConnectionsProvider = ({ children }: ProviderProps) => {
	const { organization } = useOrganization()
	const [isLoading, setIsLoading] = useState(true)
	const [bankConnections, setBankConnections] = useState<BankConnectionSummary[]>([])
	
	const getLinkToken = () => createLinkToken(organization._id)
	
	useEffect(() => {
		getOrganizationBankConnections(organization._id).then(data => {
			setBankConnections(data)
			setIsLoading(false)
		})
	}, [])
	
	const create = async (params:Omit<CreateBankConnectionParams, 'organizationId'>) => {
		const newAccount = await createBankConnection({ ...params, organizationId: organization._id })
		await getOrganizationBankConnections(organization._id).then(setBankConnections)
		
		return newAccount
	}
	
	const addAccounts = async (bankConnectionId: string, plaidAccountIds: string[]) => {
		const newConn = await addAccountsToBankConnection({
			bankConnectionId,
			plaidAccountIds,
		})
		
		await getOrganizationBankConnections(organization._id).then(setBankConnections)
		
		return newConn
	}
	

	return <Context.Provider value={{
		getLinkToken,
		isLoading,
		bankConnections,
		create,
		addAccounts,
	}}>
		{ children }
	</Context.Provider>
}

const useBankConnections = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useBankConnections outside provider!')
	}
	return val
}

export default useBankConnections

