export const LOGIN_PATH = '/Login'
export const CREATE_ACCOUNT_PATH = '/CreateAccount'
export const VERIFY_EMAIL_PATH = '/VerifyEmail'

export const MAIN_PATH = ''

export const DASHBOARD_PATH = `${MAIN_PATH}/Dashboard`

export const TIMESHEET_PATH = `${MAIN_PATH}/Timesheet`

export const CHARGE_CODE_PATH = `${MAIN_PATH}/ChargeCodes`

export const ALL_INVOICES_PATH = `${MAIN_PATH}/Invoices`

export const CLIENTS_PATH = `${MAIN_PATH}/Clients`
export const SINGLE_CLIENT_PATH = `${CLIENTS_PATH}/:clientId`
export const createClientPath = (clientId:string) => `${CLIENTS_PATH}/${clientId}`

export const ALL_EXPENSES_PATH = `${MAIN_PATH}/Expenses`
export const ALL_PAYMENTS_PATH = `${MAIN_PATH}/Payments`
export const ALL_CHARGES_PATH = `${MAIN_PATH}/AllCharges`
export const EMPLOYEES_PATH = `${MAIN_PATH}/Employees`

export const SALES_TAX_PAYMENTS_PATH = `${MAIN_PATH}/SalesTax`

export const BANK_TRANSACTIONS_PATH = `${MAIN_PATH}/BankTransactions`
export const BANK_CONNECTIONS_PATH = `${BANK_TRANSACTIONS_PATH}/BankConnections`
