import React from 'react'
import { MenuButton, Page } from 'react-ui-scaffold'
import NavBar from '../../components/NavBar/NavBar'
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useAllPayments, { AllPaymentsProvider } from '../../data-hooks/useAllPayments'
import CreatePaymentMenu from './CreatePaymentMenu'

function InnerPayments() {
	const { payments, isLoading, del } = useAllPayments()
	
	return (
		<Page className='Payments flex'>
			<Sidebar/>
			<NotSidebar>
				<NavBar title='All Payments'>
					<MenuButton
						menuContent={CreatePaymentMenu}
						className='button neutral'
						permanent
					>Create Payment</MenuButton>
				</NavBar>
				<PaymentsTable
					payments={payments}
					loading={isLoading}
					onDelete={del}
				/>
			</NotSidebar>
		</Page>
	)
}

export default function Payments(){
	return <AllPaymentsProvider>
		<InnerPayments/>
	</AllPaymentsProvider>
}
