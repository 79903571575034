import { faBan, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { Charge } from '../../../../commonTypes/ChargeTypes'

export default function ChargeHoursCell({ row }:QuickTableCellProps) {
	const charge = row as Charge
	
	return (
		<span className='ChargeHoursCell'>
			{charge.hours} {charge.unpaid && <span style={{ color: 'var(--negativeAccentColor)' }} title='unpaid'>
				<FontAwesomeIcon icon={faBan}/><FontAwesomeIcon icon={faDollarSign}/>
			</span>}
		</span>
	)
}
