import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { Invoice } from '../../../commonTypes/InvoiceTypes'
import { getOrganizationInvoices, syncInvoice } from '../api/invoices'
import useOrganization from './useOrganization'

const Context = createContext<{
	isLoading: boolean,
	invoices: Invoice[],
	sync(stripeId:string):Promise<void>
}>(null as any)


interface ProviderProps{
	children:any,
}

export const AllInvoicesProvider = ({ children }: ProviderProps) => {
	const [invoices, setInvoices] = useState<Invoice[]>([])
	const [isLoading, setIsLoading] = useState(true)
	
	const { organization } = useOrganization()
	
	useEffect(() => {
		getOrganizationInvoices(organization._id)
			.then(setInvoices)
			.finally(() => setIsLoading(false))
	}, [organization._id])
	
	const sync = useCallback(async (stripeId:string) => {
		await syncInvoice(stripeId)
		await getOrganizationInvoices(organization._id).then(setInvoices)
	}, [organization._id])

	return <Context.Provider value={{
		isLoading,
		invoices,
		sync,
	}}>
		{ children }
	</Context.Provider>
}

const useAllInvoices = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useAllInvoices outside provider!')
	}
	return val
}

export default useAllInvoices
