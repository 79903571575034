import { CionAccount } from '../../../commonTypes/UserTypes'
import beam from './beam'

interface CreateParams {
	email: string,
	password: string,
	name: string
}
export const createAccount = (body:CreateParams) => beam.post<CionAccount>('/api/accounts', body)

export const getCurrentAccount = () => beam.get<CionAccount>('/api/account')
