import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from './pages/Main/Main'
import { AuthProvider } from './data-hooks/useAuth'
import { LOGIN_PATH, MAIN_PATH } from './paths'
import Login from './pages/Login/Login'
import './stylesheets/menuStyles.css'
import './stylesheets/popupStyles.css'
import PopupProvider from './components/Popup/PopupProvider'
import VerifiedRoute from './components/VerifiedRoute'
import { ScreenSizeProvider, useScreenSize } from './hooks/useScreenSize'

function InnerApp() {
	const { screenSize } = useScreenSize()
	
	return (
		<PopupProvider className={screenSize}>
			<div className={`App ${screenSize}`}>
				<AuthProvider>
					<Router>
						<Switch>
							<Route path={LOGIN_PATH} component={Login} />
							<VerifiedRoute path={`${MAIN_PATH}/`} component={Main} />
							<Route path={'/'} component={Login} />
						</Switch>
					</Router>
				</AuthProvider>
			</div>
		</PopupProvider>
	)
}

export default function App(){
	return <ScreenSizeProvider>
		<InnerApp/>
	</ScreenSizeProvider>
}
