import React, { useState } from 'react'
import dayjs from 'dayjs'
import { FreeButton, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { ChargeCode } from '../../../../../../commonTypes/ChargeCodeTypes'
import { ReportingMonth } from '../../../../../../commonTypes/ChargeTypes'
import { DetailedClientSummary } from '../../../../../../commonTypes/ClientTypes'
import Popup from '../../../../components/Popup/Popup'
import CurrencyCell from '../../../../components/QuickTableCells/CurrencyCell'
import useSingleClient from '../../../../data-hooks/useSingleClient'
import { getReportingMonth } from '../../../../utils/dateUtils'
import { formatCurrency } from '../../../../utils/formatUtils'
import AmountInput from './AmountInput'
import './CreateInvoicePopup.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

interface Props{
	visible: boolean,
	setVisible(val:boolean): void
	client: DetailedClientSummary
}

const CURRENT_MONTH = getReportingMonth()
const NEXT_MONTH = getReportingMonth(dayjs().add(1, 'month').toDate())
const PREV_MONTH = getReportingMonth(dayjs().add(-1, 'month').toDate())

export default function CreateInvoicePopup({ visible, setVisible, client }:Props) {
	const lastInvoice = client.invoices.at(0)
	const [reportingMonth, _setReportingMonth] = useState(PREV_MONTH)
	
	const setReportingMonth = (val:ReportingMonth) => {
		_setReportingMonth(val)
		setDescription(`Hi ${client.name},\n\nYour invoice for ${val} is now ready. \nThanks for choosing Cion Studio!\n\nCheers, \nCion Studio Team`)
	}
	
	const [description, setDescription] = useState(
		`Hi ${client.name},\n\nYour invoice for ${reportingMonth} is now ready. \nThanks for choosing Cion Studio!\n\nCheers, \nCion Studio Team`
	)
	
	const { addInvoice } = useSingleClient()
	
	const [amountByChargeCode, setAmountByChargeCode] = useState(() => client.chargeCodes.map(code => ({
		amount: `${Math.max((code.totalOutstandingAmount || 0)/100, 0)}`,
		chargeCode: code,
	})))
	
	const editChargeCodeAmount = (val:string, index:number) => {
		const newAmounts = [...amountByChargeCode]
		newAmounts[index].amount = val
		setAmountByChargeCode(newAmounts)
	}
	
	const chargeCodesHeaders: QuickTableHeaders = {
		name: { title: 'Name', width: 200 },
		totalOutstandingAmount: { title: 'Outstanding', width: 150, component: CurrencyCell, props: { style: { textAlign: 'left' } } },
		amountInput: {
			title: '', width: 150, component: AmountInput, props: {
				amountByChargeCode,
				editChargeCodeAmount,
			},
		},
	}
	
	const [isLoading, setIsLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [removeTax, setRemoveTax] = useState(false)
	
	const createHandler = async () => {
		try {
			setIsLoading(true)

			const cleanAmounts = amountByChargeCode.map(code => ({
				amount: parseFloat(code.amount) * 100,
				chargeCode: code.chargeCode as ChargeCode,
			}))

			const { stripeId } = await addInvoice({
				client: client._id,
				description,
				reportingMonth,
				amountByChargeCode: cleanAmounts,
				removeTax,
			})
			
			if (stripeId){
				window.open(`https://dashboard.stripe.com/invoices/${stripeId}`)
			}
			
			setMessage('')
			document.body.click()
			
		} catch (err:any){
			setMessage(err.error)
		}
		
		setIsLoading(false)
	}
	
	return (
		<Popup className='CreateInvoicePopup' setVisible={setVisible} visible={visible} title={`Create Invoice for ${client.name}`}>
			{lastInvoice && <div className='info'>
				<b>Last Invoice: {lastInvoice.reportingMonth}</b>
				<div className='row'>
					subtotal: {formatCurrency(lastInvoice.subtotal)}
					<span className="space">
						tax: {formatCurrency(lastInvoice.tax)}
					</span>
				</div>
			</div>}
			
			<div className="story">
				<QuickTable
					headers={chargeCodesHeaders}
					primaryKey={'_id'}
					data={client.chargeCodes}
					hover
				/>
			</div>
			
			<div className="story">
				<select className="input wide" value={reportingMonth} onChange={(e) => setReportingMonth(e.target.value as ReportingMonth)}>
					<option value={PREV_MONTH}>{PREV_MONTH}</option>
					<option value={CURRENT_MONTH}>{CURRENT_MONTH}</option>
					<option value={NEXT_MONTH}>{NEXT_MONTH}</option>
				</select>
			</div>
			
			<div className="story">
				<div className="row bold">Description</div>
				<textarea
					className='input wide'
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					style={{
						minHeight: 170,
					}}
				/>
			</div>
			
			<div className="story">
				<div className="row bold">
					<input
						type='checkbox'
						id='remove-tax-input'
						className='input'
						checked={removeTax}
						onChange={(e) => setRemoveTax(e.target.checked)}
					/> <label htmlFor="remove-tax-input">Remove Tax</label>
				</div>
			</div>
			
			<div className="message">
				{message}
			</div>
			
			<div className="story">
				<FreeButton className={`button wide ${removeTax ? 'hollow' : ''}`} onClick={createHandler} loading={isLoading}>
					{removeTax && <FontAwesomeIcon icon={faExclamationTriangle}/>} Preview Invoice
				</FreeButton>
			</div>
		</Popup>
	)
}
