import React, { useContext, createContext, useEffect, useState } from 'react'
import { CreateSalesTaxPaymentParams, SalesTaxPayment } from '../../../commonTypes/SalesTaxPaymentTypes'
import { createSalesTaxPayment, deleteSalesTaxPayment, getSalesTaxPaymentsByOrganization } from '../api/salesTaxPayments'
import useOrganization from './useOrganization'

const Context = createContext<{
	isLoading: boolean,
	salesTaxPayments: SalesTaxPayment[],
	del(salesTaxPaymentId:string): Promise<void>
	create(params:CreateSalesTaxPaymentParams): Promise<void>
}>(null as any)


interface ProviderProps{
	children:any
}

export const SalesTaxPaymentsProvider = ({ children }: ProviderProps) => {
	const { organization } = useOrganization()
	
	const [isLoading, setIsLoading] = useState(true)
	const [salesTaxPayments, setSalesTaxPayments] = useState<SalesTaxPayment[]>([])
	
	useEffect(() => {
		getSalesTaxPaymentsByOrganization(organization._id)
			.then(setSalesTaxPayments)
			.catch(() => {})
			.finally(() => setIsLoading(false))
	}, [organization._id])
	
	const create = async (params:CreateSalesTaxPaymentParams) => {
		await createSalesTaxPayment(params)
		await getSalesTaxPaymentsByOrganization(organization._id).then(setSalesTaxPayments)
	}
	
	const del = async (id:string) => {
		await deleteSalesTaxPayment(id)
		await getSalesTaxPaymentsByOrganization(organization._id).then(setSalesTaxPayments)
	}

	return <Context.Provider value={{
		isLoading,
		salesTaxPayments,
		create,
		del,
	}}>
		{ children }
	</Context.Provider>
}

const useSalesTaxPayments = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSalesTaxPayments outside provider!')
	}
	return val
}

export default useSalesTaxPayments
