import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ChargeFilters } from '../../../../commonTypes/ChargeTypes'
import useAllChargeCodes from '../../data-hooks/useAllChargeCodes'

interface Props{
	filters: ChargeFilters,
	setFilters(f:ChargeFilters): void,
	isLoading?: boolean
}

export default function ExpenseFilterBar({
	filters,
	setFilters,
}: Props) {
	const { chargeCodes } = useAllChargeCodes()
	
	const changeHandler = (e:any) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		})
	}
	
	const showClearButton = filters.chargeCode || filters.reportingMonth || filters.user
	
	const clear = () => setFilters({
		chargeCode: '',
		reportingMonth: '',
		user: '',
	})
	
	return (
		<div className='FilterBar'>
			<span className="filterSection">
				<label>Charge Code</label>
				<select
					className='input wide'
					name='chargeCode'
					onChange={changeHandler}
					value={filters.chargeCode}
				>
					<option value={''}>All</option>
					{chargeCodes.map(c => <option key={c._id} value={c._id}>
						{c.name}
					</option>)}
				</select>
			</span>
			
			<span className="filterSection">
				<label>Month</label>
				<input
					className="input wide"
					name='reportingMonth'
					onChange={changeHandler}
					value={filters.reportingMonth}
				/>
			</span>
			
			{showClearButton && <span className="filterSection">
				<button className="button plain clearButton" onClick={clear}>
					<FontAwesomeIcon icon={faTimesCircle} /> Clear
				</button>
				
			</span>}
		</div>
	)
}
