import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { listClientsByOrganization, delClient, createClient } from '../api/clients'
import { Client, CreateClientParams } from '../../../commonTypes/ClientTypes'
import useOrganization from './useOrganization'

const ClientsContext = createContext<{
	clients: Client[],
	isLoading: boolean,
	del(id:string):Promise<any>,
	create(c: Omit<CreateClientParams, 'organizationId'>): Promise<any>
}>(null as any)

interface ProviderProps{
	children:any
}

export function ClientsProvider({ children }:ProviderProps){
	const [clients, setClients] = useState<Client[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const { organization } = useOrganization()
	
	useEffect(() => {
		listClientsByOrganization({	organizationId: organization._id }).then(d => {
			setClients(d)
			setIsLoading(false)
		})
	}, [organization._id])
	
	const del = useCallback(async (clientId:string) => {
		await delClient({ clientId })
		setClients(curr => curr.filter(c => c._id !== clientId))
	},[])
	
	const create = useCallback(async (c:CreateClientParams) => {
		const nc = await createClient({
			...c,
			organizationId: organization._id,
		})
		
		setClients(curr => [...curr, nc])
	}, [organization._id])
	
	return <ClientsContext.Provider value={{
		isLoading,
		clients,
		del,
		create,
	}}>
		{children}
	</ClientsContext.Provider>
}

export default function useClients(){
	const v = useContext(ClientsContext)
	if (!v){
		throw new Error('useClients outside provider')
	}
	return v
}
