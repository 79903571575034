import React, { useContext, createContext, useState } from 'react'

const Context = createContext<{
	visible: boolean,
	setVisible(v:boolean): void
}>(null as any)


interface ProviderProps{
	children:any
}

export const SidebarVisibleProvider = ({ children }: ProviderProps) => {
	const [visible, setVisible] = useState(false)

	return <Context.Provider value={{
		visible,
		setVisible,
	}}>
		{ children }
	</Context.Provider>
}

const useSidebarVisible = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSidebarVisible outside provider!')
	}
	return val
}

export default useSidebarVisible
