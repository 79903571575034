import dayjs from 'dayjs'
import React, { useState } from 'react'
import { CreateSalesTaxPaymentParams } from '../../../../commonTypes/SalesTaxPaymentTypes'
import AsyncButton from '../../components/AsyncButton'
import useOrganization from '../../data-hooks/useOrganization'
import useSalesTaxPayments from '../../data-hooks/useSalesTaxPayments'
import { getReportingMonth, getReportingYear } from '../../utils/dateUtils'

const PREV_YEAR = getReportingYear(getReportingMonth(dayjs().add(-1, 'year').toDate()))

export default function CreateSalesTaxPaymentMenu() {
	const { organization } = useOrganization()
	
	const [formData, setFormData] = useState<CreateSalesTaxPaymentParams>({
		reportingYear: PREV_YEAR,
		amount: 0,
		organization: organization._id,
		type: 'tax_payment',
	})
	
	const { create } = useSalesTaxPayments()
	
	const changeHandler = (e:any) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}
	
	const createHandler = async () => {
		if (formData.reportingYear && formData.amount && formData.type){
			await create({
				...formData,
				amount: formData.amount * 100,
			})
			setMessage('')
		} else {
			setMessage('Fill all required fields!')
		}
	}
	
	
	const [message, setMessage] = useState('')
	
	return (
		<div className='CreateSalesTaxPaymentMenu formMenu'>
			<div className='story' style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}>
				{message}
			</div>
			
			<div className="story">
				<label>Amount</label>
				<input
					type="number"
					className="input wide"
					name='amount'
					value={formData.amount}
					onChange={changeHandler}
					min='0'
				/>
			</div>
			
			<div className="story">
				<label>Type</label>
				<select
					className="input wide"
					name='type'
					value={formData.type}
					onChange={changeHandler}
				>
					<option value='tax_payment'>Tax Payment</option>
					<option value='tax_return'>Tax Return</option>
				</select>
			</div>
			
			<div className="story">
				<label>Reporting Year</label>
				<input
					type="number"
					className="input wide"
					name='reportingYear'
					value={formData.reportingYear}
					onChange={changeHandler}
					min='2000'
				/>
			</div>
			
			
			<div className="story">
				<AsyncButton
					className="button positive"
					style={{ width: '100%' }}
					onClick={createHandler}
				>
					Create
				</AsyncButton>
			</div>
		</div>
	)
}
