import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { getUserChargeCodes } from '../api/chargeCodes'
import { ChargeCode } from '../../../commonTypes/ChargeCodeTypes'
import useAuth from './useAuth'
import useOrganization from './useOrganization'


const MyChargeCodesContext = createContext<{
	chargeCodes: ChargeCode[],
	isLoading: boolean,
}>(null as any)

export function MyChargeCodesProvider({ children }: any) {
	const [chargeCodes, setChargeCodes] = useState<ChargeCode[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { account } = useAuth()
	const { organization } = useOrganization()

	useEffect(() => {
		getUserChargeCodes({
			userId: account._id,
			organizationId: organization._id,
		}).then((c) => {
			setChargeCodes(c)
			setIsLoading(false)
		})
	}, [account, organization])

	return <MyChargeCodesContext.Provider value={{
		chargeCodes,
		isLoading,
	}}>
		{children}
	</MyChargeCodesContext.Provider>
}

export default function useMyChargeCodes() {
	const val = useContext(MyChargeCodesContext)
	if (!val) {
		throw new Error('useMyChargeCodes outside provider!')
	}
	return val
}
