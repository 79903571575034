import React, { useCallback } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { createPayment, CreatePaymentParams, deletePayment, listPaymentsByOrganization } from '../api/payments'
import { Payment } from '../../../commonTypes/PaymentTypes'
import useAuth from './useAuth'
import useOrganization from './useOrganization'


const AllPaymentsContext = createContext<{
	payments: Payment[],
	isLoading: boolean,
	create(c: CreatePaymentParams): Promise<void>,
	del(id: string): Promise<any>
}>(null as any)

export function AllPaymentsProvider({ children }: any) {
	const [payments, setPayments] = useState<Payment[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { account } = useAuth()
	const { organization } = useOrganization()

	useEffect(() => {
		listPaymentsByOrganization({
			organizationId: organization?._id as string,
		}).then((c) => {
			setIsLoading(false)
			setPayments(c)
		})
	}, [account, organization])

	const create = useCallback(async (c: CreatePaymentParams) => {
		await createPayment({
			...c,
			organizationId: organization._id,
		})
		await listPaymentsByOrganization({ organizationId: organization?._id }).then(setPayments)
	}, [])

	const del = useCallback(async (paymentId: string) => {
		await deletePayment({ paymentId })
		await listPaymentsByOrganization({ organizationId: organization?._id }).then(setPayments)
	}, [])

	return <AllPaymentsContext.Provider value={{
		payments,
		isLoading,
		create,
		del,
	}}>
		{children}
	</AllPaymentsContext.Provider>
}

export default function useAllPayments() {
	const val = useContext(AllPaymentsContext)
	if (!val) {
		throw new Error('useAllPayments outside provider!')
	}
	return val
}
