import { faExclamationTriangle, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Page, QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { OrganizationSummary } from '../../../../commonTypes/OrganizationTypes'
import AsyncButton from '../../components/AsyncButton'
import NavBar from '../../components/NavBar/NavBar'
import CurrencyCell from '../../components/QuickTableCells/CurrencyCell'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useOrganization from '../../data-hooks/useOrganization'
import useOrganizationSummary, { OrganizationSummaryProvider } from '../../data-hooks/useOrganizationSummary'
import useFormatCurrencyMemo from '../../hooks/useFormatCurrencyMemo'
import { BANK_CONNECTIONS_PATH } from '../../paths'
import { formatCurrency } from '../../utils/formatUtils'
import './Dashboard.css'

interface Props{
	summary: OrganizationSummary
}

function InnerDashboard({ summary }:Props) {
	const monthTableHeaders:QuickTableHeaders = {
		reportingMonth: { title: 'Month', width: 200 },
		totalRevenue: { title: 'Revenue', width: 150, component: CurrencyCell },
		totalTimeSpend: { title: 'Time Spend', width: 150, component: CurrencyCell },
		totalExpenseSpend: { title: 'Expense Spend', width: 150, component: CurrencyCell },
		profit: { title: 'Profit', width: 150, component: CurrencyCell },
	}
	
	const { organization, syncAccountValue } = useOrganization()
	const totalInAccount = (organization.totalInAccount || 0)
	
	const summaryHeaders:QuickTableHeaders = {
		totalPendingPayments: { title: 'Pending Payments', width: 200, component: CurrencyCell },
		totalIncomingMoney: { title: 'Incoming Money', width: 200, component: CurrencyCell },
		totalHSTCollected: { title: 'HST Collected', width: 150, component: CurrencyCell },
	}
	
	const netAvailableFunds = useFormatCurrencyMemo(
		(totalInAccount) + (summary.totalIncomingMoney*0.97) - summary.totalPendingPayments - summary.totalHSTCollected
	)
	
	const [balanceError, setBalanceError] = useState(false)
	
	const syncHandler = () => syncAccountValue()
		.then(() => setBalanceError(false))
		.catch(() => setBalanceError(true))
	
	return (
		<div className='Dashboard'>
			<section>
				<h2>Total In Account</h2>
				<div className="space">
					{formatCurrency(totalInAccount)}
					<AsyncButton className="plain refreshAccountValue" onClick={syncHandler}>
						<FontAwesomeIcon icon={faSync}/>
					</AsyncButton>
				</div>
				{balanceError && <div className="balanceError">
					<Link className='button negative bold plain' to={BANK_CONNECTIONS_PATH}>
						<FontAwesomeIcon icon={faExclamationTriangle} /> Error fetching balance
					</Link>
				</div>}
				
				<h2>Net Available Funds</h2>
				<div className="space">
					{netAvailableFunds}
				</div>
			</section>
			
			<section>
				<h2>Summary</h2>
				<QuickTable
					headers={summaryHeaders}
					data={[summary]}
					primaryKey='totalPendingPayments'
				/>
			</section>
				
			<section className="months">
				<h2>History</h2>
				<QuickTable
					headers={monthTableHeaders}
					hover
					data={summary.months}
					primaryKey='reportingMonth'
				/>
			</section>
		</div>
	)
}

function InnerDashboardWrapper(){
	const { summary, isLoading } = useOrganizationSummary()
	
	return <div className='flex'>
			<Sidebar/>
			<NotSidebar>
				<NavBar title='Dashboard'/>
				{isLoading || !summary ? <Page loading/> : <InnerDashboard summary={summary}/>}
			</NotSidebar>
		</div>
}

export default function Dashboard(){
	return <OrganizationSummaryProvider>
		<InnerDashboardWrapper/>
	</OrganizationSummaryProvider>
}
