import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { CLIENTS_PATH, SINGLE_CLIENT_PATH } from '../../paths'
import Clients from './Clients'
import SingleClient from './SingleClient/SingleClient'

export default function ClientsRouter() {
	return (
		<Switch>
			<Route path={SINGLE_CLIENT_PATH} component={SingleClient}/>
			<Route path={CLIENTS_PATH} component={Clients} />
		</Switch>
	)
}
