import React from 'react'
import { QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { ChargeCodeSummary } from '../../../../../commonTypes/ChargeCodeTypes'
import CurrencyCell from '../../../components/QuickTableCells/CurrencyCell'
import RedCurrencyCell from '../../../components/QuickTableCells/RedCurrencyCell'

interface Props {
	chargeCodes: ChargeCodeSummary[],
}

export default function ClientChargeCodes({ chargeCodes }: Props) {
	const headers: QuickTableHeaders = {
		name: { title: 'Charge Code', width: 200 },
		totalOutstandingAmount: { title: 'Outstanding', component: RedCurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalTimeCashflowIn: { title: 'Time', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalPreTaxExpenses: { title: 'Expenses', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalPreTaxInvoicedAmount: { title: 'Invoiced', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalCreditAmmount: { title: 'Credits', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		totalTimeCashflowOut: { title: 'Cashflow Out', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		profit: { title: 'Profit', width: 150, component: CurrencyCell, props: { style: { textAlign: 'left' } } },
	}

	return (
		<div className='Credits'>
			<QuickTable
				headers={headers}
				data={chargeCodes}
				primaryKey='_id'
				hover
			/>
		</div>
	)
}
