import { ChargeCode } from '../../../commonTypes/ChargeCodeTypes'
import { CreateInvoiceParams, Invoice } from '../../../commonTypes/InvoiceTypes'
import beam from './beam'

export const createInvoice = (params: CreateInvoiceParams) => beam.post<Invoice>(`/api/clients/${params.client}/invoices`, params)

export const deleteInvoice = (invoiceId: string) => beam.del(`/api/invoices/${invoiceId}`)

export const getClientInvoices = (clientId: string) => beam.get<Invoice<
	string,
	string,
	ChargeCode,
	string
>[]>(`/api/clients/${clientId}/invoices`)

export const getOrganizationInvoices = (organizationId:string) => beam.get<Invoice[]>(`/api/organizations/${organizationId}/invoices`)

export const updateInvoiceWarning = ({
	ignored,
	invoiceId,
	warningId,
}:{
	ignored: boolean,
	invoiceId: string,
	warningId: string,
}) => beam.patch<Invoice>(`/api/invoices/${invoiceId}/warnings/${warningId}`, { ignored })

export const syncInvoice = (invoiceId:string) => beam.patch<string>(`/api/invoices/${invoiceId}/sync`)
