import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { BankTransaction } from '../../../../commonTypes/BankingTypes'
import AsyncButton from '../../components/AsyncButton'
import useBankTransactions from '../../data-hooks/useBankTransactions'

export default function MarkAsResolvedCell({ row }:QuickTableCellProps) {
	const transaction = row as BankTransaction
	
	if (transaction.parent){
		return null
	}
	
	const { markAsResolved } = useBankTransactions()
	
	const icon = transaction.resolved ? faTimes : faCheck
	const title = transaction.resolved ? 'Mark as not resolved' : 'Mark as resolved'
	
	const clickHandler = () => markAsResolved(
		transaction._id,
		transaction.resolved ? false : true
	)
	
	return (
		<span style={{ color: 'gray' }}>
			<AsyncButton title={title} className='plain' onClick={clickHandler}>
				<FontAwesomeIcon icon={icon} />
			</AsyncButton>
		</span>
	)
}
