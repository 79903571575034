import beam from './beam'
import { Charge, ChargeFilters, CreateChargeParams } from '../../../commonTypes/ChargeTypes'
import { formatQueryString } from '../utils/formatUtils'

interface GetUserChargesParams{
	organizationId: string,
	userId: string
}

export const getUserCharges = ({ organizationId, userId }: GetUserChargesParams) => beam.get<Charge[]>(`/api/organizations/${organizationId}/users/${userId}/charges`)

export const createCharge = (p: CreateChargeParams) => beam.post(`/api/chargeCodes/${p.chargeCodeId}/charges`, {
	userId: p.userId,
	hours: p.hours,
	notes: p.notes,
	reportingMonth: p.reportingMonth,
	unpaid: p.unpaid,
})

interface DelChargeParams{
	chargeId: string
}

export const delCharge = ({ chargeId }:DelChargeParams) => beam.del(`/api/charges/${chargeId}`)

interface GetAllChargesParams{
	organizationId: string,
	filters: ChargeFilters
}

export const getAllCharges = ({
	organizationId,
	filters,
}: GetAllChargesParams) => beam.get<Charge[]>(`/api/organizations/${organizationId}/charges${formatQueryString(filters)}`)
