import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { useScreenSize } from '../../hooks/useScreenSize'
import { ALL_CHARGES_PATH, CHARGE_CODE_PATH, CLIENTS_PATH, ALL_EXPENSES_PATH, ALL_PAYMENTS_PATH, TIMESHEET_PATH, EMPLOYEES_PATH, DASHBOARD_PATH, ALL_INVOICES_PATH, SALES_TAX_PAYMENTS_PATH, BANK_TRANSACTIONS_PATH } from '../../paths'
import './Sidebar.css'
import SideButton from './SideButton'
import useSidebarVisible from './useSidebarVisible'
import useOrganization from '../../data-hooks/useOrganization'

interface Props{
	children?:any,
}

export default function Sidebar({ children }:Props) {
	
	const { path } = useRouteMatch()
	const { screenSize } = useScreenSize()
	const { visible, setVisible } = useSidebarVisible()
	const { organization } = useOrganization()
	
	if (screenSize === 'mobile' && !visible){
		return null
	}

	return (
		<div className='Sidebar'>
			<div className="branding">
				<h1>
					<button
						className="closeButton noDesktop noTablet button plain"
						onClick={() => setVisible(false)}
					>
						<FontAwesomeIcon icon={faTimes}/>
					</button>
					
					{organization.name}
				</h1>
			</div>

			<div className="links">
				<SideButton to={DASHBOARD_PATH} activeLink={path}>Dashboard</SideButton>
				<SideButton to={TIMESHEET_PATH} activeLink={path}>My Timesheet</SideButton>
				<SideButton to={ALL_CHARGES_PATH} activeLink={path} admin>All Charges</SideButton>
				<SideButton to={ALL_EXPENSES_PATH} activeLink={path} admin>All Expenses</SideButton>
				<SideButton to={ALL_PAYMENTS_PATH} activeLink={path} admin>All Payments</SideButton>
				<SideButton to={SALES_TAX_PAYMENTS_PATH} activeLink={path} admin>Sales Tax Payments</SideButton>
				<SideButton to={ALL_INVOICES_PATH} activeLink={path} admin>All Invoices</SideButton>
				<SideButton to={CHARGE_CODE_PATH} activeLink={path} admin>Charge Codes</SideButton>
				<SideButton to={CLIENTS_PATH} activeLink={path} admin>Clients</SideButton>
				<SideButton to={EMPLOYEES_PATH} activeLink={path} admin>Employees</SideButton>
				<SideButton to={BANK_TRANSACTIONS_PATH} activeLink={path} admin>Bank Transactions</SideButton>
			</div>

			<div className="children">
				{children}
			</div>
		</div>
	)
}
