import { faCreditCard, faUniversity } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { ConfirmButton } from 'react-ui-scaffold'
import { BankAccount, PlaidBankAccount } from '../../../../../commonTypes/BankingTypes'

interface Props{
	connectedAccounts: BankAccount[],
	bankConnectionId: string,
	option: PlaidBankAccount,
	onConnect(bankConnectionId: string, plaidAccountIds: string[]): Promise<void>
}

export default function BankAccountOption({ connectedAccounts, option, bankConnectionId, onConnect }:Props) {
	const [isLoading, setIsLoading] = useState(false)
	
	const [isConnected, setIsConnected] = useState(
		() => connectedAccounts.some((ca) => ca.plaidAccountId === option.account_id)
	)
	
	const clickHandler = async () => {
		setIsLoading(true)
		await onConnect(bankConnectionId, [option.account_id])
		setIsConnected(true)
		setIsLoading(false)
	}
	
	return (
		<div className='BankAccountOption'>
			<span className={`icon ${option.type}`}>
				<FontAwesomeIcon icon={option.type==='credit' ? faCreditCard : faUniversity}/>
			</span>
			{`${option.name} - ${option.mask}`}
			
			<span className="connectControls">
				{isConnected && <span style={{ color: 'var(--positiveAccentColor)', fontWeight: 'bold' }}>
					Connected
				</span>}

				{!isConnected && <ConfirmButton
					loading={isLoading}
					content={'Connect'}
					expandedContent={'Confirm'}
					className={'connectButton button plain bold'}
					onClick={clickHandler}
				/>}
			</span>
		</div>
	)
}
