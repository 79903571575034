import React, { useContext, createContext, useEffect, useState } from 'react'
import { OrganizationSummary } from '../../../commonTypes/OrganizationTypes'
import { getOrganizationSummary } from '../api/organization'
import useOrganization from './useOrganization'

const Context = createContext<{
	summary?: OrganizationSummary,
	isLoading: boolean,
}>(null as any)


interface ProviderProps{
	children:any,
}

export const OrganizationSummaryProvider = ({ children }: ProviderProps) => {
	const [summary, setSummary] = useState<OrganizationSummary>()
	const [isLoading, setIsLoading] = useState(true)
	
	const { organization } = useOrganization()
	
	useEffect(() => {
		getOrganizationSummary(organization._id)
			.then(setSummary)
			.finally(() => setIsLoading(false))
	}, [organization._id])

	return <Context.Provider value={{
		summary,
		isLoading,
	}}>
		{ children }
	</Context.Provider>
}

const useOrganizationSummary = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useOrganizationSummary outside provider!')
	}
	return val
}

export default useOrganizationSummary
