import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'

const colorMap:any = {
	open: 'rgb(0, 102, 255)',
	void: 'rgb(168, 179, 189)',
	paid: 'rgb(0, 190, 0)',
	overdue: 'rgb(124, 0, 0)',
	draft: 'gray',
	uncollectible: 'rgb(168, 179, 189)',
}

export default function StatusCell({ cell, style }:QuickTableCellProps) {
	return (
		<span className='StatusCell' style={{
			...style,
			backgroundColor: colorMap[cell],
		}}>
			<span className="text">
				{cell}
			</span>
		</span>
	)
}
