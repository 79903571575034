import { CreateExpenseParams, Expense, ExpenseFilters } from '../../../commonTypes/ExpenseTypes'
import { formatQueryString } from '../utils/formatUtils'
import beam from './beam'

export const createExpense = ({
	chargeCodeId,
	userId,
	notes,
	reportingMonth,
	amount,
	taxable,
	amountChargedToClient,
}: CreateExpenseParams) => {
	const tax = taxable ? 0.13*amount : 0
	
	return beam.post<Expense>(`/api/chargeCodes/${chargeCodeId}/expenses`, {
		userId,
		notes,
		reportingMonth,
		amount,
		tax,
		amountChargedToClient,
	})
}

export const listExpensesByOrganization = ({ organizationId, filters }: { organizationId: string, filters?: ExpenseFilters }) => beam.get<Expense[]>(`/api/organizations/${organizationId}/expenses${formatQueryString(filters as any)}`)

export const deleteExpense = ({ expenseId }: { expenseId: string }) => beam.del(`/api/expenses/${expenseId}`)
