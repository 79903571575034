import React, { useState } from 'react'
import { ControlledTabContainer, MenuButton, Page, InfoTab } from 'react-ui-scaffold'
import { DetailedClientSummary } from '../../../../../commonTypes/ClientTypes'
import InvoiceTable from '../../../components/InvoiceTable/InvoiceTable'
import NavBar from '../../../components/NavBar/NavBar'
import NotSidebar from '../../../components/Sidebar/NotSidebar'
import Sidebar from '../../../components/Sidebar/Sidebar'
import TabHeaders, { TabOption } from '../../../components/TabHeaders/TabHeaders'
import useSingleClient, { SingleClientProvider } from '../../../data-hooks/useSingleClient'
import ClientChargeCodes from './ClientChargeCodes'
import CreateCreditMenu from './CreateCreditMenu'
import CreateInvoicePopup from './CreateInvoicePopup/CreateInvoicePopup'
import Credits from './Credits'

interface Props{
	client: DetailedClientSummary
}

function SingleClientInternal({ client }:Props) {
	const [activeTabId, setActiveTabId] = useState('invoices')
	const { removeCredit, removeInvoice, syncClientInvoice } = useSingleClient()
	
	const tabs:TabOption[] = [
		{ id: 'chargeCodes', title: 'Charge Codes' },
		{ id: 'invoices', title: 'Invoices' },
		{ id: 'credits', title: 'Credits' },
	]

	const [showCreateInvoice, setShowCreateInvoice] = useState(false)
	
	return (
		<Page>
			<CreateInvoicePopup visible={showCreateInvoice} setVisible={setShowCreateInvoice} client={client}/>
			<NavBar title={client.name}>
				<MenuButton
					className='button'
					menuContent={CreateCreditMenu}
					menuProps={{ client }}
					permanent
				>Create Credit</MenuButton>
				<button className="button plain neutral" onClick={() => setShowCreateInvoice(true)}>Create Invoice</button>
			</NavBar>
			
			<TabHeaders
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
				tabs = {tabs}
			/>
			<ControlledTabContainer activeTab={activeTabId}>
				<InfoTab tabID='invoices'>
					<InvoiceTable
						invoices={client.invoices}
						onDelete={removeInvoice}
						onSyncInvoice={syncClientInvoice}
					/>
				</InfoTab>
				
				<InfoTab tabID='chargeCodes'>
					<ClientChargeCodes chargeCodes={client.chargeCodes}/>
				</InfoTab>
				
				<InfoTab tabID='credits'>
					<Credits credits={client.credits} onDelete={removeCredit}/>
				</InfoTab>
			</ControlledTabContainer>
		</Page>
	)
}

function SingleClientLoader(){
	const { client, isLoading } = useSingleClient()
	
	return <div className="flex">
		<Sidebar/>
		<NotSidebar>
			<Page loading={isLoading}>
				{client&& <SingleClientInternal client={client as DetailedClientSummary} />}
			</Page>
		</NotSidebar>
	</div>
}

export default function SingleClient({ match }:any){
	return <SingleClientProvider clientId={match.params.clientId}>
		<SingleClientLoader />
	</SingleClientProvider>
}
