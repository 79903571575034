import React, { useState } from 'react'
import { ConfirmButton } from 'react-ui-scaffold'
import Popup from '../../Popup/Popup'
import useAllChargeCodes from '../../../data-hooks/useAllChargeCodes'
import { ChargeCode, ChargeCodeMember } from '../../../../../commonTypes/ChargeCodeTypes'

interface Props {
	visible?: boolean,
	setVisible(v: boolean): void,
	member: ChargeCodeMember,
	chargeCode: ChargeCode
}

export default function EditMemberPopup({ visible, setVisible, member, chargeCode }: Props) {

	const [data, setData] = useState({
		rateIn: member.rateIn ? member.rateIn/100 : null,
		rateOut: member.rateOut ? member.rateOut/100 : null,
	})
	
	const { updateMemberRate, removeMember } = useAllChargeCodes()

	const [message, setMessage] = useState('')

	const changeHandler = (e: any) => setData({
		...data,
		[e.target.name]: e.target.value,
	})
	
	const submit = async () => {
		updateMemberRate(chargeCode._id, member.data._id, {
			...data,
			rateIn: data.rateIn? (data.rateIn as number) * 100 : undefined,
			rateOut: data.rateOut ? (data.rateOut as number) * 100 : undefined,
		}).then(() => {
			setVisible(false)
		}).catch(({ error }) => {
			setMessage(error)
		})
	}
	
	const removeHandler = () => {
		removeMember(chargeCode._id, member.data._id).then(() => {
			setVisible(false)
		}).catch(({ error }) => {
			setMessage(error)
		})
	}
	return (
		<Popup
			className='AddMemberPopup'
			visible={visible}
			setVisible={setVisible}
			title={<><span className='highlight'>{`${member.data.firstName} ${member.data.lastName}`}</span> in <span className='highlight'>{chargeCode.name}</span></>}
		>
			<div className="story message">
				{message}
			</div>

			<div className="story">
				<div className='name'>Rate paid</div>
				<input
					type='number'
					className="input wide"
					name='rateOut'
					value={data.rateOut || ''}
					onChange={changeHandler}
				/>
			</div>

			{chargeCode.client && <div className="story">
				<div className='name'>Rate charged to client</div>
				<input
					type='number'
					className="input wide"
					name='rateIn'
					value={data.rateIn || ''}
					onChange={changeHandler}
				/>
			</div>}

			<div className="story">
				<button className="button wide" onClick={submit}>Save</button>
			</div>
			
			<div className="story flexX">
				<ConfirmButton
					className="button  negative hollow"
					content='Remove from charge code'
					expandedContent='Confirm remove?'
					onClick={removeHandler}
				/>
			</div>
		</Popup>
	)
}
