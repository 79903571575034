import React, { useCallback } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { createCharge, delCharge, getUserCharges } from '../api/charges'
import { Charge, CreateChargeParams } from '../../../commonTypes/ChargeTypes'
import useAuth from './useAuth'
import useOrganization from './useOrganization'


const MyChargesContext = createContext<{
	charges: Charge[],
	isLoading: boolean,
	create(c:CreateChargeParams): Promise<void>,
	del(id:string):Promise<any>
}>(null as any)

export function MyChargesProvider({ children }: any) {
	const [charges, setCharges] = useState<Charge[]>([])
	const [isLoading, setIsLoading] = useState(true)
	
	const { account } = useAuth()
	const { organization } = useOrganization()
	
	useEffect(() => {
		getUserCharges({
			organizationId: organization?._id as string,
			userId: account._id,
		}).then((c:Charge[]) => {
			setIsLoading(false)
			setCharges(c)
		})
	},[account, organization])
	
	const create = useCallback(async (c:CreateChargeParams) => {
		await createCharge(c)
		await getUserCharges({
			organizationId: organization?._id as string,
			userId: account._id,
		}).then(setCharges)
	},[])
	
	const del = useCallback(async (chargeId:string) => {
		await delCharge({ chargeId })
		await getUserCharges({
			organizationId: organization?._id as string,
			userId: account._id,
		}).then(setCharges)
	},[])

	return <MyChargesContext.Provider value={{
		charges,
		isLoading,
		create,
		del,
	}}>
		{children}
	</MyChargesContext.Provider>
}

export default function useMyCharges() {
	const val = useContext(MyChargesContext)
	if (!val) {
		throw new Error('useCharges outside provider!')
	}
	return val
}
