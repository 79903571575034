import { EmployeeSummary } from '../../../commonTypes/UserTypes'
import beam from './beam'
import { AddOrganizationMemberParams, OrganizationMember } from '../../../commonTypes/OrganizationTypes'

export const getAllEmployeesSummary = ({ organizationId }: { organizationId: string }) => beam.get<EmployeeSummary[]>(`/api/${organizationId}/employees`)

export const addEmployeeToOrganization = ({
	organizationId,
	params,
}: { organizationId: string, params: AddOrganizationMemberParams }) => beam.post<OrganizationMember>(`/api/organizations/${organizationId}/employees`, params)

export const updateEmployeeRate = ({
	organizationId,
	userId,
	rate,
}: { organizationId: string, userId: string, rate: number }) => beam.patch<OrganizationMember>(`/api/organizations/${organizationId}/employees/${userId}`, { rate })

export const ACCESS_LEVELS = ['member', 'admin']

export const removeEmployeeFromOrganization = ({
	organizationId,
	userId,
}: { organizationId: string, userId: string }) => beam.del<OrganizationMember>(`/api/organizations/${organizationId}/employees/${userId}`)
