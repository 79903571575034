import React from 'react'
import { QuickTable, QuickTableCellProps, QuickTableHeaders } from 'react-ui-scaffold'
import ChargeHoursCell from '../../../components/QuickTableCells/ChargeHoursCell'
import CurrencyCell from '../../../components/QuickTableCells/CurrencyCell'
import DeleteCell from '../../../components/QuickTableCells/DeleteCell'
import './ChargeTable.css'

interface Props{
	charges: any[],
	loading?: boolean,
	onDelete(id:string):any
}

export default function ChargeTable({ charges, loading, onDelete }:Props) {
	const headers: QuickTableHeaders = {
		user: { title: 'Employee', width: 200, component: ({ cell }: QuickTableCellProps) => <>{`${cell.firstName || ''} ${cell.lastName || ''}`}</> },
		chargeCode: { title: 'Charge Code', width: 200, component: ({ cell }: QuickTableCellProps) => <>{cell.name}</> },
		reportingMonth: { title: 'Month', width: 200 },
		hours: { title: 'Hours', width: 150, component: ChargeHoursCell },
		cashFlowIn: { title: 'Amount In', width: 200, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		cashFlowOut: { title: 'Amount Out', width: 200, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		notes: { title: 'Notes', width: 250 },
		delete: {
			title: '',
			width: 100,
			component: DeleteCell,
			props: {
				onDelete: onDelete,
			},
		},
	}
	
	if ((charges.length === 0) && (!loading)) {
		return <div className="ChargeTable">
			<div className='noCharges'>
				You have no charges to display
			</div>
		</div>
	}

	return (
		<div className="ChargeTable">
			<QuickTable
				data={charges}
				headers={headers}
				primaryKey={'_id'}
				hover
			/>
		</div>
	)
}
