import React from 'react'
import { QuickTable, QuickTableCellProps, QuickTableHeaders } from 'react-ui-scaffold'
import CurrencyCell from '../../components/QuickTableCells/CurrencyCell'
import DeleteCell from '../../components/QuickTableCells/DeleteCell'
import { EmployeeSummary } from '../../../../commonTypes/UserTypes'
import RedCurrencyCell from '../../components/QuickTableCells/RedCurrencyCell'
import UpdateRateCell from './UpdateRateCell'

interface Props{
	employees: EmployeeSummary[],
	loading?: boolean,
	onDelete(id: string): any
}

export default function EmployeesTable({ employees, loading, onDelete }:Props) {
	const headers: QuickTableHeaders = {
		name: { title: 'Name', width: 200, component: ({ row }: QuickTableCellProps) => <>{`${row.firstName || ''} ${row.lastName || ''}`}</> },
		defaultRate: { title: 'Rate ($/hr)', width: 150, component: UpdateRateCell },
		amountPaid_cash: { title: 'Cash Paid', width: 150, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		amountPaid_expense: { title: 'Expenses Paid', width: 150, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		amountPaid_untracked: { title: 'Untracked Paid', width: 150, component: CurrencyCell, headerStyle:{ textAlign:'center' } },
		amountOwed: { title: 'Amount Owed', width: 150, component: RedCurrencyCell, headerStyle: { textAlign: 'center' } },
		delete: {
			title: '',
			width: 100,
			component: DeleteCell,
			props: {
				onDelete,
			},
		},
	}
	
	if ((employees.length === 0) && (!loading)) {
		return <div className="ChargeTable">
			<div className='noCharges'>
				You have no employees to display
			</div>
		</div>
	}

	return (
		<div className="ChargeTable EmployeesTable">
			<QuickTable
				data={employees}
				headers={headers}
				primaryKey={'_id'}
				hover
			/>
		</div>
	)
}
