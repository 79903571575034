import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { addEmployeeToOrganization, getAllEmployeesSummary, removeEmployeeFromOrganization, updateEmployeeRate } from '../api/employees'
import { EmployeeSummary } from '../../../commonTypes/UserTypes'
import useOrganization from './useOrganization'
import { AddOrganizationMemberParams } from '../../../commonTypes/OrganizationTypes'

const Context = createContext<{
	employees: EmployeeSummary[],
	isLoading: boolean,
	add(params:AddOrganizationMemberParams): Promise<void>
	remove(userId: string): Promise<void>
	setRate(userId: string, rate: number): Promise<void>
}>(null as any)


interface ProviderProps{
	children:any
}

export const EmployeesProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [employees, setEmployees] = useState<EmployeeSummary[]>([])
	const { organization } = useOrganization()
	
	useEffect(() => {
		getAllEmployeesSummary({ organizationId: organization._id }).then((data) => {
			setEmployees(data)
			setIsLoading(false)
		})
	}, [])
	
	const add = useCallback(async (params:AddOrganizationMemberParams) => {
		await addEmployeeToOrganization({
			organizationId: organization._id,
			params,
		})
		
		getAllEmployeesSummary({ organizationId: organization._id }).then(setEmployees)
	}, [organization])
	
	const remove = useCallback(async (userId:string) => {
		await removeEmployeeFromOrganization({
			organizationId: organization._id,
			userId,
		})

		getAllEmployeesSummary({ organizationId: organization._id }).then(setEmployees)
	}, [organization])
	
	const setRate = useCallback(async (userId: string, rate: number) => {
		await updateEmployeeRate({
			organizationId: organization._id,
			userId,
			rate,
		})

		getAllEmployeesSummary({ organizationId: organization._id }).then(setEmployees)
	}, [organization])

	return <Context.Provider value={{
		employees,
		isLoading,
		add,
		remove,
		setRate,
	}}>
		{ children }
	</Context.Provider>
}

const useEmployees = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useEmployees outside provider!')
	}
	return val
}

export default useEmployees
