import dayjs from 'dayjs'
import React, { useState } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import { ChargeCode } from '../../../../../commonTypes/ChargeCodeTypes'
import { DetailedClientSummary } from '../../../../../commonTypes/ClientTypes'
import { CreateCreditParams } from '../../../../../commonTypes/CreditTypes'
import useSingleClient from '../../../data-hooks/useSingleClient'
import { getReportingMonth } from '../../../utils/dateUtils'

interface Props{
	client: DetailedClientSummary,
}

const CURRENT_MONTH = getReportingMonth()
const NEXT_MONTH = getReportingMonth(dayjs().add(1, 'month').toDate())
const PREV_MONTH = getReportingMonth(dayjs().add(-1, 'month').toDate())

export default function CreateCreditMenu({ client }:Props) {
	const [formData, setFormData] = useState<Partial<CreateCreditParams>>({
		reportingMonth: PREV_MONTH,
		chargeCode: '',
		amount: 0,
		notes: '',
	})

	function changeHandler(e: any) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}
	const chargeCodes = client.chargeCodes
	const { addCredit } = useSingleClient()

	const [message, setMessage] = useState('')

	const createHandler = () => {
		if (formData.reportingMonth && formData.amount && formData.chargeCode) {
			addCredit({
				...formData,
				client: client._id,
				amount: formData.amount * 100,
			} as CreateCreditParams)
			
			setMessage('')
		} else {
			setMessage('Fill all required fields!')
		}
	}

	return (
		<div className='CreateChargeMenu formMenu'>
			<div className='story' style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}>
				{message}
			</div>

			<div className="story">
				<label>Amount</label>
				<input
					type="number"
					className="input wide"
					name='amount'
					value={formData.amount}
					onChange={changeHandler}
					min='0'
				/>
			</div>

			<div className="story">
				<label>Charge Code</label>
				<select
					className="input wide"
					name='chargeCode'
					value={formData.chargeCode}
					onChange={changeHandler}
				>
					<option value=''></option>
					{chargeCodes.map((c: ChargeCode) => <option value={c._id} key={c._id}>
						{c.name}
					</option>)}
				</select>
			</div>

			<div className="story">
				<label>Reporting Month</label>
				<select
					className='wide input'
					name='reportingMonth'
					value={formData.reportingMonth}
					onChange={changeHandler}
				>
					<option value={PREV_MONTH}>{PREV_MONTH}</option>
					<option value={CURRENT_MONTH}>{CURRENT_MONTH}</option>
					<option value={NEXT_MONTH}>{NEXT_MONTH}</option>
				</select>
			</div>

			<div className="story">
				<label>Notes</label>
				<textarea
					className="input wide"
					name='notes'
					value={formData.notes}
					onChange={changeHandler}
					style={{ resize: 'vertical' }}
				/>
			</div>

			<div className="story">
				<FreeButton
					className="button positive"
					style={{ width: '100%' }}
					onClick={createHandler}
				>
					Create
				</FreeButton>
			</div>

		</div>
	)
}
