import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { CELL_SPAN_STYLE } from './cellStyles'

export default function DateCell({ cell }: QuickTableCellProps) {
	const formattedDate = useMemo(() => dayjs(cell).format('DD-MMM'), [cell])

	return (
		<span
			style={{
				...CELL_SPAN_STYLE,
			}}
		>
			{formattedDate}
		</span>
	)
}
