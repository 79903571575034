import React from 'react'
import { QuickTable, QuickTableCellProps, QuickTableHeaders } from 'react-ui-scaffold'
import { Expense } from '../../../../commonTypes/ExpenseTypes'
import CurrencyCell from '../QuickTableCells/CurrencyCell'
import DeleteCell from '../QuickTableCells/DeleteCell'

interface Props{
	expenses: Expense[],
	loading?: boolean,
	onDelete(id:string):any
}

export default function ExpenseTable({ expenses, loading, onDelete }:Props) {
	const headers: QuickTableHeaders = {
		chargeCode: { title: 'Charge Code', width: 200, component: ({ cell }: QuickTableCellProps) => <>{cell.name}</> },
		reportingMonth: { title: 'Month', width: 200 },
		amount: { title: 'Amount Paid', width: 150, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		amountChargedToClient: { title: 'Charged to client', width: 200, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		tax: { title: 'Tax', width: 150, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		total: { title: 'Total', width: 150, component: CurrencyCell, headerStyle: { textAlign: 'center' } },
		notes: { title: 'Notes', width: 300 },
		delete: {
			title: '',
			width: 100,
			component: DeleteCell,
			props: {
				onDelete: onDelete,
			},
		},
	}
	
	if ((expenses.length === 0) && (!loading)) {
		return <div className="ChargeTable">
			<div className='noCharges'>
				You have no expenses to display
			</div>
		</div>
	}

	return (
		<div className="ChargeTable ExpenseTable">
			<QuickTable
				data={expenses}
				headers={headers}
				primaryKey={'_id'}
				hover
			/>
		</div>
	)
}
