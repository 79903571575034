import React, { useState } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import useAllChargeCodes from '../../data-hooks/useAllChargeCodes'
import useClients from '../../data-hooks/useClients'

export default function CreateChargeCodeMenu() {
	const [formData, setFormData] = useState(() => ({
		clientId: undefined,
		name: '',
	}))
	
	const { create } = useAllChargeCodes()
	const { clients } = useClients()
	
	const [loading, setLoading] = useState(false)

	function changeHandler(e: any) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}

	const createHandler = async () => {
		if (formData.name){
			setLoading(true)
			await create({
				name: formData.name,
				client: formData.clientId,
			}).catch(() => {
				setLoading(false)
			})
			setLoading(false)
		}
	}

	return (
		<div className='CreateChargeMenu formMenu'>
			<div className="story">
				<label>Name</label>
				<input
					className="input wide"
					name='name'
					value={formData.name}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label>Client</label>
				<select
					className="input wide"
					name='clientId'
					value={formData.clientId}
					onChange={changeHandler}
				>
					<option value=''></option>
					{clients.map((c: any) => <option value={c._id} key={c._id}>
						{c.name}
					</option>)}
				</select>
			</div>

			<div className="story">
				<FreeButton
					className="button positive"
					style={{ width: '100%' }}
					onClick={createHandler}
					loading={loading}
				>
					Create
				</FreeButton>
			</div>

		</div>
	)
}
