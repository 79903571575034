import React from 'react'

interface IOptions{
	componentProps?: any,
	providerProps?: any,
}

const wrapWithProvider = (Component:any, Provider:any, { providerProps }:IOptions={}) => (props:any) => {
	return <Provider {...providerProps}>
		<Component {...props}/>
	</Provider>
		
}

export default wrapWithProvider
