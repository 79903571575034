import React, { useState } from 'react'
import { ChargeCode, ChargeCodeMember } from '../../../../../commonTypes/ChargeCodeTypes'
import { formatCurrency } from '../../../utils/formatUtils'
import EditMemberPopup from './EditMemberPopup'

interface Props{
	member: ChargeCodeMember,
	chargeCode: ChargeCode
}

export default function MemberCard({ member, chargeCode }:Props) {
	const [active, setActive] = useState(false)
	
	return (
		<>
			<EditMemberPopup
				visible={active}
				setVisible={setActive}
				member={member}
				chargeCode={chargeCode}
			/>
			<button className='button wide MemberCard' onClick={() => setActive(true)}>
				{`${member.data.firstName} ${member.data.lastName}`} {member.rateOut && `(${formatCurrency(member.rateOut)}/hr)`}
			</button>
		</>
	)
}
