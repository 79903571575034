import React, { useState } from 'react'
import { MenuButton, Page } from 'react-ui-scaffold'
import ChargeTable from './ChargeTable/ChargeTable'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useAllCharges, { AllChargesProvider } from '../../data-hooks/useAllCharges'
import CreateAnyChargeMenu from './CreateAnyChargeMenu'
import { AllChargeCodesProvider } from '../../data-hooks/useAllChargeCodes'
import FilterBar from './FilterBar'
import { ChargeFilters } from '../../../../commonTypes/ChargeTypes'

function InnerAllCharges({
	filters,
	setFilters,
}:{
	setFilters(f: ChargeFilters): void,
	filters: ChargeFilters,
}) {
	const { charges, isLoading, del } = useAllCharges()
	
	return (
		<Page className='Timesheet flex'>
			<Sidebar/>
			<NotSidebar>
				<AllChargeCodesProvider>
					<NavBar title='All Charges'>
						<MenuButton
							menuContent={CreateAnyChargeMenu}
							className='button neutral'
							permanent
						>Create Charge</MenuButton>
					</NavBar>
					
					<FilterBar
						filters={filters}
						setFilters={setFilters}
					/>
					
					<ChargeTable
						charges={charges}
						loading={isLoading}
						onDelete={del}
					/>
				</AllChargeCodesProvider>
			</NotSidebar>
		</Page>
	)
}

export default function AllCharges(){
	const [filters, setFilters] = useState<ChargeFilters>({
		reportingMonth: '',
		user: '',
		chargeCode: '',
	})
	
	return <AllChargesProvider
		filters = {filters}
	>
		<InnerAllCharges
			setFilters = {setFilters}
			filters={filters}
		/>
	</AllChargesProvider>
}
