import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Page } from 'react-ui-scaffold'
import AdminRoute from '../../components/AdminRoute'
import useAuth from '../../data-hooks/useAuth'
import useOrganization, { OrganizationProvider } from '../../data-hooks/useOrganization'
import { ALL_CHARGES_PATH, CHARGE_CODE_PATH, CLIENTS_PATH, ALL_EXPENSES_PATH, MAIN_PATH, ALL_PAYMENTS_PATH, TIMESHEET_PATH, EMPLOYEES_PATH, DASHBOARD_PATH, ALL_INVOICES_PATH, SALES_TAX_PAYMENTS_PATH, BANK_CONNECTIONS_PATH, BANK_TRANSACTIONS_PATH } from '../../paths'
import AllCharges from '../AllCharges/AllCharges'
import ChargeCodes from '../ChargeCodes/ChargeCodes'
import Expenses from '../AllExpenses/AllExpenses'
import Payments from '../Payments/Payments'
import Timesheet from '../Timesheet/Timesheet'
import EmployeesPage from '../EmployeesPage/EmployeesPage'
import ClientsRouter from '../Clients/ClientsRouter'
import Dashboard from '../Dashboard/Dashboard'
import AllInvoices from '../AllInvoices/AllInvoices'
import { SidebarVisibleProvider } from '../../components/Sidebar/useSidebarVisible'
import SalesTaxPaymentsPage from '../SalesTaxPayments/SalesTaxPaymentsPage'
import BankConnectionsPage from '../BankConnections/BankConnectionsPage'
import BankTransactionsPage from '../BankTransactions/BankTransactionsPage'

function InternalMain() {
	
	const auth = useAuth()
	const org = useOrganization()
	
	if (auth.isLoading || org.isLoading){
		return <Page loading/>
	}
	
	return (
		<SidebarVisibleProvider>
			<Switch>
				<Route path={TIMESHEET_PATH} component={Timesheet}/>
				<AdminRoute path={CHARGE_CODE_PATH} component={ChargeCodes}/>
				<AdminRoute path={CLIENTS_PATH} component={ClientsRouter}/>
				<AdminRoute path={ALL_EXPENSES_PATH} component={Expenses}/>
				<AdminRoute path={ALL_PAYMENTS_PATH} component={Payments}/>
				<AdminRoute path={SALES_TAX_PAYMENTS_PATH} component={SalesTaxPaymentsPage}/>
				<AdminRoute path={ALL_CHARGES_PATH} component={AllCharges}/>
				<AdminRoute path={ALL_INVOICES_PATH} component={AllInvoices}/>
				<AdminRoute path={EMPLOYEES_PATH} component={EmployeesPage}/>
				<AdminRoute path={DASHBOARD_PATH} component={Dashboard}/>
				<AdminRoute path={BANK_CONNECTIONS_PATH} component={BankConnectionsPage}/>
				<AdminRoute path={BANK_TRANSACTIONS_PATH} component={BankTransactionsPage}/>
				
				<Route path={`${MAIN_PATH}/`}>
					{org.accessLevel === 'admin' ? <Redirect to={DASHBOARD_PATH}/> :<Redirect to={TIMESHEET_PATH}/>}
				</Route>
			</Switch>
		</SidebarVisibleProvider>
	)
}

export default function Main(){
	return <OrganizationProvider>
		<InternalMain/>
	</OrganizationProvider>
}
