import React, { useCallback } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { createExpense, deleteExpense, listExpensesByOrganization } from '../api/expenses'
import { CreateExpenseParams, Expense, ExpenseFilters } from '../../../commonTypes/ExpenseTypes'
import useAuth from './useAuth'
import useOrganization from './useOrganization'


const AllExpensesContext = createContext<{
	expenses: Expense[],
	isLoading: boolean,
	create(c: CreateExpenseParams): Promise<void>,
	del(id: string): Promise<any>,
}>(null as any)

interface ProviderProps{
	filters?: ExpenseFilters,
	children: any
}

export function AllExpensesProvider({ children, filters }: ProviderProps) {
	const [expenses, setExpenses] = useState<Expense[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { account } = useAuth()
	const { organization } = useOrganization()

	useEffect(() => {
		listExpensesByOrganization({
			organizationId: organization?._id as string,
			filters,
		}).then((c) => {
			setIsLoading(false)
			setExpenses(c)
		})
	}, [account, organization, filters])

	const create = useCallback(async (c: CreateExpenseParams) => {
		await createExpense(c)
		await listExpensesByOrganization({ organizationId: organization?._id }).then(setExpenses)
	}, [])

	const del = useCallback(async (expenseId: string) => {
		await deleteExpense({ expenseId })
		await listExpensesByOrganization({ organizationId: organization?._id }).then(setExpenses)
	}, [])

	return <AllExpensesContext.Provider value={{
		expenses,
		isLoading,
		create,
		del,
	}}>
		{children}
	</AllExpensesContext.Provider>
}

export default function useAllExpenses() {
	const val = useContext(AllExpensesContext)
	if (!val) {
		throw new Error('useAllExpenses outside provider!')
	}
	return val
}
