import React, { useCallback } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { getOrganization, syncOrganizationAccountValue } from '../api/organization'
import { Organization } from '../../../commonTypes/OrganizationTypes'
import useAuth from './useAuth'

const OrganizationContext = createContext<{
	organization: Organization,
	isLoading: boolean,
	accessLevel: string,
	syncAccountValue(): Promise<void>
}>(null as any)

const DEFAULT_ORGANIZATION = process.env.REACT_APP_ORG_ID as string

export function OrganizationProvider({ children }: {
	children: any,
}) {
	const [organization, setOrganization] = useState<Organization>()
	const [isLoading, setIsLoading] = useState(true)
	const [accessLevel, setAccessLevel] = useState('')
	const { account } = useAuth()
	
	useEffect(() => {
		getOrganization(account.activeOrganization || DEFAULT_ORGANIZATION).then((org:Organization) => {
			setOrganization(org)
			const me = org.members.find(m => m.user._id === account._id)
			if (me){
				setAccessLevel(me.accessLevel)
			}
			setIsLoading(false)
		})
	},[account.activeOrganization])
	
	const syncAccountValue = useCallback(async () => {
		const newAmount = await syncOrganizationAccountValue(organization?._id as string)
		setOrganization({ ...organization, totalInAccount: newAmount } as Organization)
	}, [organization])

	return <OrganizationContext.Provider value={{
		isLoading,
		organization: organization as Organization,
		accessLevel,
		syncAccountValue,
	}}>
		{children}
	</OrganizationContext.Provider>
}

export default function useOrganization() {
	const val = useContext(OrganizationContext)
	if (!val) {
		throw new Error('useCharges outside provider!')
	}
	return val
}
