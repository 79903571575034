import React, { useCallback } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { addMembersToChargeCode, createChargeCode, delChargeCode, getChargeCodeSummaries, removeMemberFromChargeCode, updateChargeCodeMemberRate } from '../api/chargeCodes'
import { ChargeCode, ChargeCodeMember, ChargeCodeSummary } from '../../../commonTypes/ChargeCodeTypes'
import useAuth from './useAuth'
import useOrganization from './useOrganization'


const AllChargeCodesContext = createContext<{
	chargeCodes: ChargeCodeSummary[],
	isLoading: boolean,
	create(c:Partial<ChargeCode>): Promise<void>,
	addMembers(chargeCodeId:string, members:Partial<ChargeCodeMember>[]): Promise<void>
	updateMemberRate(chargeCodeId: string, memberId: string, updates: { rateIn?: number, rateOut?: number }): Promise<void>
	removeMember(chargeCodeId: string, memberId: string): Promise<void>
	del(id:string):Promise<any>
}>(null as any)

export function AllChargeCodesProvider({ children }: any) {
	const [chargeCodes, setChargeCodes] = useState<ChargeCodeSummary[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { account } = useAuth()
	const { organization } = useOrganization()
	
	const fetchCodes = () => {
		getChargeCodeSummaries({
			organizationId: organization._id,
		}).then((c) => {
			setChargeCodes(c)
			setIsLoading(false)
		})
	}

	useEffect(fetchCodes, [account, organization])
	
	const create = async ({ name, client }:Partial<ChargeCode>) => {
		await createChargeCode({
			clientId: client,
			members: [],
			name: name as string,
			organizationId: organization._id,
		})
		fetchCodes()
	}
	
	const del = useCallback(async (chargeCodeId:string) => {
		const res = await delChargeCode({ chargeCodeId })
		fetchCodes()
		return res
	},[])
	
	const addMembers = useCallback(async (chargeCodeId: string, members:Partial<ChargeCodeMember>[]) => {
		await addMembersToChargeCode({
			chargeCodeId,
			members,
		})
		fetchCodes()
	},[])
	
	const removeMember = useCallback(async (chargeCodeId: string, memberId: string) => {
		await removeMemberFromChargeCode({
			memberId,
			chargeCodeId,
		})
		fetchCodes()
	}, [])
	
	const updateMemberRate = useCallback(async (chargeCodeId:string, memberId:string, updates:{rateIn?:number, rateOut?:number}) => {
		await updateChargeCodeMemberRate({
			memberId,
			chargeCodeId,
			rateOut: updates.rateOut,
			rateIn: updates.rateIn,
		})
		fetchCodes()
	}, [])

	return <AllChargeCodesContext.Provider value={{
		chargeCodes,
		isLoading,
		create,
		addMembers,
		updateMemberRate,
		removeMember,
		del,
	}}>
		{children}
	</AllChargeCodesContext.Provider>
}

export default function useAllChargeCodes() {
	const val = useContext(AllChargeCodesContext)
	if (!val) {
		throw new Error('useAllChargeCodes outside provider!')
	}
	return val
}
