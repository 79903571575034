import React from 'react'
import { MenuButton, Page } from 'react-ui-scaffold'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useAllChargeCodes, { AllChargeCodesProvider } from '../../data-hooks/useAllChargeCodes'
import { ClientsProvider } from '../../data-hooks/useClients'
import ChargeCodesTable from '../../components/ChargeCodeTable/ChargeCodeTable'
import CreateChargeCodeMenu from './CreateChargeCodeMenu'

function InnerChargeCodes() {
	
	const { chargeCodes, isLoading } = useAllChargeCodes()
	
	return (
		<Page className='ChargeCodes flex'>
			<Sidebar/>
			<NotSidebar>
				<NavBar title='Charge Codes'>
					<ClientsProvider>
						<MenuButton
							menuContent={CreateChargeCodeMenu}
							className='button'
							permanent
						>Create</MenuButton>
					</ClientsProvider>
				</NavBar>
				
				<ChargeCodesTable
					chargeCodes={chargeCodes}
					loading={isLoading}
				/>
			</NotSidebar>
		</Page>
	)
}

export default function ChargeCodes(){
	return <AllChargeCodesProvider>
		<InnerChargeCodes />
	</AllChargeCodesProvider>
}
