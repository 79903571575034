import React from 'react'
import { MenuButton, Page } from 'react-ui-scaffold'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useClients, { ClientsProvider } from '../../data-hooks/useClients'
import ClientsTable from './ClientsTable'
import CreateClientMenu from './CreateClientMenu'

function InnerClients() {
	const { clients, isLoading } = useClients()

	return (
		<div className='Clients flex'>
			<Sidebar />
			<NotSidebar>
				<Page loading={isLoading}>
					<NavBar title='Clients'>
						<MenuButton
							menuContent={CreateClientMenu}
							className='button'
							permanent
						>Create Client</MenuButton>
					</NavBar>

					<ClientsTable
						clients={clients}
						loading={isLoading}
					/>
				</Page>
			</NotSidebar>
		</div>
	)
}

export default function Clients() {
	return <ClientsProvider>
		<InnerClients/>
	</ClientsProvider>
}
