import React from 'react'
import { Redirect } from 'react-router-dom'
import useOrganization from '../data-hooks/useOrganization'
import { MAIN_PATH } from '../paths'
import ProtectedRoute from './ProtectedRoute'

interface Props {
	children?: any,
	path: string,
	component?: any,
}

export default function AdminRoute({ children, path, component }: Props) {

	const { accessLevel } = useOrganization()

	if (accessLevel !== 'admin') {
		return <Redirect to={MAIN_PATH} />
	}

	return <ProtectedRoute path={path} component={component}>{children}</ProtectedRoute>
}
