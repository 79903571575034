import dayjs from 'dayjs'
import React, {  useMemo, useState } from 'react'
import AsyncButton from './AsyncButton'
import useAllChargeCodes from '../data-hooks/useAllChargeCodes'
import useAuth from '../data-hooks/useAuth'
import useOrganization from '../data-hooks/useOrganization'
import { getReportingMonth } from '../utils/dateUtils'
import { CreateExpenseParams } from '../../../commonTypes/ExpenseTypes'

const CURRENT_MONTH = getReportingMonth()
const NEXT_MONTH = getReportingMonth(dayjs().add(1, 'month').toDate())
const PREV_MONTH = getReportingMonth(dayjs().add(-1, 'month').toDate())

interface Props{
	create(c: CreateExpenseParams): Promise<void>,
	prefillFields?: Partial<CreateExpenseParams>
}

export default function CreateExpenseMenu({ create, prefillFields }:Props) {
	const { chargeCodes } = useAllChargeCodes()
	const { account } = useAuth()
	const { organization } = useOrganization()
	
	const [lockAutoSplitTax, setLockAutoSplitTax] = useState(false)
	
	const [formData, setFormData] = useState<Partial<CreateExpenseParams>>({
		reportingMonth: prefillFields?.reportingMonth || PREV_MONTH,
		chargeCodeId: prefillFields?.chargeCodeId || '',
		amount: prefillFields?.amount || 0,
		amountChargedToClient: prefillFields?.amountChargedToClient || 0,
		userId: account._id,
		taxable: true,
		notes: prefillFields?.notes || '',
	})
	
	const [chargeDifferentAmount, setChargeDifferentAmount] = useState(false)
	
	const chargeDifferentAmountHandler = ({ target }:any) => {
		setChargeDifferentAmount(target.checked)
		
		if (target.checked){
			setFormData({
				...formData,
				amountChargedToClient: formData.amount,
			})
		}
	}
	
	const changeHandler = (e: any) => {
		setLockAutoSplitTax(false)
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}
	
	const [message, setMessage] = useState('')
	
	const createHandler = async () => {
		if (formData.reportingMonth && formData.amount && formData.chargeCodeId && formData.notes){
			setLockAutoSplitTax(false)
			
			//only taxable expenses can be charged to clients
			const chargeCodeUsed = chargeCodes.find((c) => c._id === formData.chargeCodeId)
			if (!chargeCodeUsed){
				return setMessage('Invalid charge code')
			}
			
			if (chargeCodeUsed.client && !formData.taxable){
				return setMessage('Only taxable expenses can be charged to clients!')
			}
			
			const clientAmountInDollars = chargeDifferentAmount ? formData.amountChargedToClient : formData.amount
			
			await create({
				...formData,
				amount: 100 * formData.amount,
				amountChargedToClient: 100 * (clientAmountInDollars as number),
				userId: account._id,
			} as CreateExpenseParams)
			
			setMessage('')
		} else {
			setMessage('Fill all required fields!')
		}
	}
	
	const splitTaxHandler = () => {
		if (lockAutoSplitTax){
			return
		}
		
		const num = (formData.amount || 0) / 1.13
		const roundedValue = Math.round((num + Number.EPSILON) * 100) / 100
		
		setFormData({
			...formData,
			amount: roundedValue,
			amountChargedToClient: roundedValue,
			taxable: true,
		})
		
		setLockAutoSplitTax(true)
	}
	
	const extraMonthOption = useMemo(() => {
		const vals:any = [PREV_MONTH, CURRENT_MONTH, NEXT_MONTH]
		if (prefillFields?.reportingMonth && !vals.includes(prefillFields?.reportingMonth)){
			return <option value={prefillFields?.reportingMonth}>{prefillFields?.reportingMonth}</option>
		}
	}, [prefillFields?.reportingMonth])
	
	return (
		<div className='CreateChargeMenu formMenu'>
			<div className='story' style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}>
				{message}
			</div>
			
			<div className="story">
				<label>Amount</label>
				<input
					type="number"
					className="input wide"
					name='amount'
					value={formData.amount}
					onChange={changeHandler}
					min='0'
				/>
				<button className="button plain wide" onClick={splitTaxHandler} disabled={lockAutoSplitTax}>
					Auto Split Tax
				</button>
			</div>
			
			<div className="story">
				<label>Charge different amount to client</label>

				<input
					type="checkbox"
					className="input"
					checked={chargeDifferentAmount}
					onChange={chargeDifferentAmountHandler}
				/>
				<input
					disabled={!chargeDifferentAmount}
					type="number"
					className="input wide"
					name='amountChargedToClient'
					value={chargeDifferentAmount ? formData.amountChargedToClient : formData.amount}
					onChange={changeHandler}
					min='0'
				/>
			</div>
			
			<div className="story">
				<label>Taxable</label>
				
				<input
					type="checkbox"
					className="input"
					name='taxable'
					checked={formData.taxable}
					onChange={({ target }) => setFormData({ ...formData, taxable: target.checked })}
					min='0'
				/>
			</div>

			<div className="story">
				<label>Charge Code</label>
				<select
					className="input wide"
					name='chargeCodeId'
					value={formData.chargeCodeId}
					onChange={changeHandler}
				>
					<option value=''></option>
					{chargeCodes.map((c) => <option value={c._id} key={c._id}>
						{c.name}
					</option>)}
				</select>
			</div>
			
			<div className="story">
				<label>Reporting Month</label>
				<select
					className='wide input'
					name='reportingMonth'
					value={formData.reportingMonth}
					onChange={changeHandler}
				>
					<option value=''></option>
					<option value={PREV_MONTH}>{PREV_MONTH}</option>
					<option value={CURRENT_MONTH}>{CURRENT_MONTH}</option>
					<option value={NEXT_MONTH}>{NEXT_MONTH}</option>
					{extraMonthOption}
				</select>
			</div>
			
			<div className="story">
				<label>Employee</label>
				<select
					className="input wide"
					name='userId'
					value={formData.userId}
					onChange={changeHandler}
				>
					<option value=''></option>
					{organization.members.map((c) => <option value={c.user._id} key={c.user._id}>
						{`${c.user.firstName} ${c.user.lastName}`}
					</option>)}
				</select>
			</div>

			<div className="story">
				<label>Notes</label>
				<textarea
					className="input wide"
					name='notes'
					value={formData.notes}
					onChange={changeHandler}
					style={{ resize: 'vertical' }}
				/>
			</div>

			<div className="story">
				<AsyncButton
					className="button positive"
					style={{ width: '100%' }}
					onClick={createHandler}
				>
					Create
				</AsyncButton>
			</div>

		</div>
	)
}
