import React, { useState } from 'react'
import { FreeButton, QuickTableCellProps } from 'react-ui-scaffold'
import { EmployeeSummary } from '../../../../commonTypes/UserTypes'
import useFormatCurrencyMemo from '../../hooks/useFormatCurrencyMemo'
import Popup from '../../components/Popup/Popup'
import useEmployees from '../../data-hooks/useEmployees'

interface Props{
	visible: boolean,
	setVisible(b:boolean): void,
	employee: EmployeeSummary
}

function RatePopup({ visible, setVisible, employee }:Props){
	const [val, setVal] = useState(employee.defaultRate)
	const { setRate } = useEmployees()
	const [isLoading, setIsLoading] = useState(false)
	
	const save = async () => {
		setIsLoading(true)
		
		await setRate(employee._id, val)
		
		setVisible(false)
		setIsLoading(false)
	}
	
	return <Popup visible={visible} setVisible={setVisible} title={`Set Rate for ${employee.firstName}`}>
		<div style={{ paddingBottom: '20px' }}>
			<input type="number" className="input wide" value={val/100} onChange={(e) => setVal(parseFloat(e.target.value) * 100)} />
		</div>
		
		<FreeButton loading = {isLoading} className="button wide" onClick={save}>Save</FreeButton>
	</Popup>
}

export default function UpdateRateCell({ row, cell }:QuickTableCellProps) {
	const displayValue = useFormatCurrencyMemo(cell)
	const [showPopup, setShowPopup] = useState(false)
	
	return (
		<>
			<RatePopup
				visible={showPopup}
				setVisible={setShowPopup}
				employee={row}
			/>
			<button className='button plain' style={{ fontWeight: 'normal' }} onClick={() => setShowPopup(true)}>
				{displayValue}
			</button>
		</>
	)
}
