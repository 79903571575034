import React, { useState } from 'react'
import { MenuButton, Page } from 'react-ui-scaffold'
import ExpenseTable from '../../components/ExpenseTable/ExpenseTable'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import { AllChargeCodesProvider } from '../../data-hooks/useAllChargeCodes'
import useAllExpenses, { AllExpensesProvider } from '../../data-hooks/useAllExpenses'
import CreateExpenseMenu from '../../components/CreateExpenseMenu'
import { ExpenseFilters } from '../../../../commonTypes/ExpenseTypes'
import ExpenseFilterBar from './ExpenseFilterBar'

function InnerExpenses({ setFilters, filters }:any) {
	const { expenses, isLoading, del, create } = useAllExpenses()
	
	return (
		<Page className='Expenses flex'>
			<Sidebar/>
			<NotSidebar>
				<AllChargeCodesProvider>
					<NavBar title='All Expenses'>
						<MenuButton
							menuContent={CreateExpenseMenu}
							className='button neutral'
							permanent
							menuProps={{
								create,
							}}
						>Create Expense</MenuButton>
					</NavBar>
					
					<ExpenseFilterBar
						filters={filters}
						setFilters={setFilters}
					/>
					
					<ExpenseTable
						expenses={expenses}
						loading={isLoading}
						onDelete={del}
					/>
				</AllChargeCodesProvider>
			</NotSidebar>
		</Page>
	)
}

export default function Expenses(){
	const [filters, setFilters] = useState<ExpenseFilters>({})
	
	return <AllExpensesProvider filters={filters}>
		<InnerExpenses filters={filters} setFilters={setFilters}/>
	</AllExpensesProvider>
}
