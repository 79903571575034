import React from 'react'
import { QuickTable, QuickTableCellProps, QuickTableHeaders } from 'react-ui-scaffold'
import { Payment } from '../../../../commonTypes/PaymentTypes'
import CurrencyCell from '../QuickTableCells/CurrencyCell'
import DeleteCell from '../QuickTableCells/DeleteCell'

interface Props{
	payments: Payment[],
	loading?: boolean,
	onDelete(id:string):any
}

export default function PaymentsTable({ payments, loading, onDelete }:Props) {
	
	const headers: QuickTableHeaders = {
		user: { title: 'Employee', width: 200, component: ({ cell }: QuickTableCellProps) => <>{`${cell.firstName || ''} ${cell.lastName || ''}`}</> },
		reportingMonth: { title: 'Month', width: 150 },
		amount: { title: 'Amount', width: 200, component: CurrencyCell, headerStyle:{ textAlign:'center' } },
		type: { title: 'Type', width: 200 },
		notes: { title: 'Notes', width: 300 },
		delete: {
			title: '',
			width: 100,
			component: DeleteCell,
			props: {
				onDelete: onDelete,
			},
		},
	}
	
	if ((payments.length === 0) && (!loading)) {
		return <div className="ChargeTable">
			<div className='noCharges'>
				You have no payments to display
			</div>
		</div>
	}

	return (
		<div className="ChargeTable PaymentsTable">
			<QuickTable
				data={payments}
				headers={headers}
				primaryKey={'_id'}
				hover
			/>
		</div>
	)
}
