import { Client, CreateClientParams, DetailedClientSummary } from '../../../commonTypes/ClientTypes'
import beam from './beam'

export const createClient = (params:CreateClientParams) => beam.post(`/api/organizations/${params.organizationId}/clients`, params)

interface DelParams{
	clientId: string
}

export const delClient = ({ clientId }: DelParams) => beam.del(`/api/clients/${clientId}`)


interface listParams{
	organizationId:string
}

export const listClientsByOrganization = ({ organizationId }: listParams) => beam.get<Client[]>(`/api/organizations/${organizationId}/clients`)

export const getClientSummary = ({
	clientId,
}: { clientId: string }) => beam.get<DetailedClientSummary>(`/api/clients/${clientId}`)
