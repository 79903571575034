import { ReportingMonth } from '../../../commonTypes/ChargeTypes'
import { Payment, PaymentType } from '../../../commonTypes/PaymentTypes'
import beam from './beam'

export interface CreatePaymentParams{
	organizationId: string,
	userId: string,
	reportingMonth: ReportingMonth,
	amount: number,
	notes?: string,
	type: PaymentType
}

export const createPayment = (p: CreatePaymentParams) => beam.post<Payment>(`/api/organizations/${p.organizationId}/payments`, p)

export const deletePayment = ({ paymentId }: { paymentId: string }) => beam.del(`/api/payments/${paymentId}`)

export const listPaymentsByOrganization = ({ organizationId }: { organizationId: string }) => beam.get<Payment[]>(`/api/organizations/${organizationId}/payments`)
