import React, { useContext, createContext, useEffect, useState } from 'react'
import { BankTransaction, UpdateBanktransactionParams } from '../../../commonTypes/BankingTypes'
import { CreateExpenseParams } from '../../../commonTypes/ExpenseTypes'
import { getOrganizationBankTransactions, updateBankTransaction } from '../api/bankTransactions'
import { createExpense } from '../api/expenses'
import { syncOrganizationBankTransactions } from '../api/organization'
import useOrganization from './useOrganization'

const Context = createContext<{
	isLoading: boolean,
	bankTransactions: BankTransaction[],
	update(p:UpdateBanktransactionParams): Promise<void>
	addExpense(bankTransactionId:string, c: CreateExpenseParams): Promise<void>,
	refresh(): Promise<void>,
	markAsResolved(bankTransactionId:string, resolved:boolean):Promise<void>
}>(null as any)


interface ProviderProps{
	children:any
}

export const BankTransactionsProvider = ({ children }: ProviderProps) => {
	const { organization } = useOrganization()
	const [isLoading, setIsLoading] = useState(true)
	const [bankTransactions, setBankTransactions] = useState<BankTransaction[]>([])
	
	useEffect(() => {
		getOrganizationBankTransactions(organization._id)
			.then(setBankTransactions)
			.finally(() => setIsLoading(false))
	}, [])
	
	const update = async (p:UpdateBanktransactionParams) => {
		await updateBankTransaction(p)
		await getOrganizationBankTransactions(organization._id).then(setBankTransactions)
	}
	
	const addExpense = async (bankTransactionId:string, c: CreateExpenseParams) => {
		const newExpense = await createExpense(c)
		await update({
			bankTransactionId,
			parentId: newExpense._id,
			parentType: 'Expense',
			resolved: true,
		})
	}
	
	const refresh = async () => {
		await syncOrganizationBankTransactions(organization._id)
		await getOrganizationBankTransactions(organization._id).then(setBankTransactions)
	}
	
	const markAsResolved = async (bankTransactionId: string, resolved: boolean) => {
		await update({
			bankTransactionId,
			parentId: null,
			parentType: null,
			resolved,
		})
	}
	
	return <Context.Provider value={{
		isLoading,
		bankTransactions,
		update,
		addExpense,
		refresh,
		markAsResolved,
	}}>
		{ children }
	</Context.Provider>
}

const useBankTransactions = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useBankTransactions outside provider!')
	}
	return val
}

export default useBankTransactions
