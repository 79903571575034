import React from 'react'
import { Page } from 'react-ui-scaffold'
import { Invoice } from '../../../../commonTypes/InvoiceTypes'
import InvoiceTable from '../../components/InvoiceTable/InvoiceTable'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useAllInvoices, { AllInvoicesProvider } from '../../data-hooks/useAllInvoices'

interface InnerProps{
	invoices: Invoice[]
}

function Inner({ invoices }:InnerProps) {
	const { sync } = useAllInvoices()
	
	return (
		<div>
			<InvoiceTable
				invoices={invoices}
				showClient
				onSyncInvoice={sync}
			/>
		</div>
	)
}

function InnerWrapper(){
	
	const { isLoading, invoices } = useAllInvoices()
	
	return <div className='flex'>
		<Sidebar/>
		<NotSidebar>
			<NavBar title='Dashboard'/>
			{isLoading ? <Page loading/> : <Inner invoices={invoices}/>}
		</NotSidebar>
	</div>
}

export default function AllInvoices(){
	return <AllInvoicesProvider>
		<InnerWrapper/>
	</AllInvoicesProvider>
}
