import { BankConnectionSummary, CreateBankConnectionParams, PlaidBankAccount } from '../../../commonTypes/BankingTypes'
import beam from './beam'

export const createLinkToken = (organizationId:string) => beam.get<string>(`/api/organizations/${organizationId}/linkToken`)

export const createUpdateLinkToken =  (bankConnectionId:string) => beam.get<string>(`/api/bankConnections/${bankConnectionId}/updateLinkToken`)

export const createBankConnection = (params:CreateBankConnectionParams) => beam.post<BankConnectionSummary>(`/api/organizations/${params.organizationId}/bankConnections`, params)

export const getOrganizationBankConnections = (organizationId:string) => beam.get<BankConnectionSummary[]>(`/api/organizations/${organizationId}/bankConnections`)

export const getPlaidAccountsForBankConnection = (bankConnectionId:string) => beam.get<PlaidBankAccount[]>(`/api/bankConnections/${bankConnectionId}/plaidAccounts`)

export const addAccountsToBankConnection = ({ bankConnectionId, plaidAccountIds }:{
	bankConnectionId: string,
	plaidAccountIds: string[]
}) => beam.post<BankConnectionSummary>(`/api/bankConnections/${bankConnectionId}/bankAccounts`, { plaidAccountIds })
