import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import Popup from '../Popup/Popup'


export default function DeleteCell({ row, itemName, onDelete }: QuickTableCellProps) {
	const [isOpen, setIsOpen] = useState(false)
	const [message, setMessage] = useState('')
	
	
	const deleteHandler = useCallback(async () => {
		try {
			await onDelete(row._id)
			setMessage('')
			setIsOpen(false)
		} catch (err:any){
			setMessage(err?.error)
		}
	}, [])
	
	return (
		<>
			<Popup
				setVisible={setIsOpen}
				visible={isOpen}
				title={<>Delete <span className="highlight">{itemName || row.name}</span>?</>}
				onExit={() => {
					setMessage('')
					setIsOpen(false)
				}}
			>
				<div className="story message" style={{ color: 'var(--negativeAccentColor' }}>
					{message}
				</div>
				<div className="flexX story">
					<button className="button" style={{ marginRight: 20 }} onClick={deleteHandler}>Delete</button>
					<button className="button hollow" onClick={() => setIsOpen(false)}>Cancel</button>
				</div>
			</Popup>
			
			<button className='button cellButton plain' onClick={() => setIsOpen(true)}>
				<FontAwesomeIcon icon={faTrash} />
			</button>
		</>
	)
}
