import beam from './beam'
import { ChargeCode, ChargeCodeMember, ChargeCodeSummary } from '../../../commonTypes/ChargeCodeTypes'

interface CreateParams{
	organizationId: string,
	name: string,
	clientId: string,
	members: ChargeCodeMember[]
}

export const createChargeCode = ({ organizationId, name, clientId, members }: CreateParams) => beam.post(`/api/organizations/${organizationId}/chargeCodes`, {
	name,
	clientId,
	members,
})

interface DelParams{
	chargeCodeId: string,
}

export const delChargeCode = ({ chargeCodeId }:DelParams) => beam.del(`/api/chargeCodes/${chargeCodeId}`)

interface AddMembersParams{
	members: Partial<ChargeCodeMember>[],
	chargeCodeId: string,
}

export const addMembersToChargeCode = ({ chargeCodeId, members }: AddMembersParams) => beam.post(`/api/chargeCodes/${chargeCodeId}/members`, { members })

interface RemoveMemberParams{
	chargeCodeId: string,
	memberId: string
}

export const removeMemberFromChargeCode = ({ chargeCodeId, memberId }: RemoveMemberParams) => beam.del(`/api/chargeCodes/${chargeCodeId}/members/${memberId}`)

interface UpdateRateParams{
	chargeCodeId: string,
	memberId: string,
	rateIn?: number,
	rateOut?: number
}

export const updateChargeCodeMemberRate = ({
	memberId,
	chargeCodeId,
	rateIn,
	rateOut,
}: UpdateRateParams) => beam.patch(`/api/chargeCodes/${chargeCodeId}/members/${memberId}`, {
	rateIn,
	rateOut,
})

interface GetByUserParams{
	organizationId: string,
	userId: string
}

export const getUserChargeCodes = ({ organizationId, userId }:GetByUserParams) => beam.get(`/api/organizations/${organizationId}/users/${userId}/chargeCodes`)

interface GetAllParams{
	organizationId: string
}

export const getAllChargeCodes = ({ organizationId }: GetAllParams):Promise<ChargeCode[]> => beam.get(`/api/organizations/${organizationId}/chargeCodes`)

export const getChargeCodeSummaries = ({ organizationId }: GetAllParams): Promise<ChargeCodeSummary[]> => beam.get(`/api/organizations/${organizationId}/chargeCodeSummaries`)
