import React from 'react'
import { QuickTable, QuickTableHeaders } from 'react-ui-scaffold'
import { Credit } from '../../../../../commonTypes/CreditTypes'
import CurrencyCell from '../../../components/QuickTableCells/CurrencyCell'
import DeleteCell from '../../../components/QuickTableCells/DeleteCell'
import ChargeCodeCell from './ChargeCodeCell'

interface Props{
	credits: Credit[],
	onDelete(creditId:string): Promise<void>
}

export default function Credits({ credits, onDelete }:Props) {
	const headers:QuickTableHeaders = {
		reportingMonth: { title: 'Month', width: 150 },
		chargeCode: { title: 'Charge Code', component: ChargeCodeCell, width: 200 },
		amount: { title: 'Amount', component: CurrencyCell, width: 140, props: { style: { textAlign: 'left' } } },
		notes: { title: 'Notes', width: 300 },
		delete: { title: '', width: 40, component: DeleteCell, props: { onDelete } },
	}
	
	return (
		<div className='Credits'>
			<QuickTable
				headers={headers}
				data={credits}
				primaryKey='_id'
			/>
		</div>
	)
}
