import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { ChargeCode } from '../../../../../commonTypes/ChargeCodeTypes'

export default function ChargeCodeCell({ cell }:QuickTableCellProps) {
	const code = cell as ChargeCode
	return (
		<span>
			{code.name}
		</span>
	)
}
