import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { DetailedClientSummary } from '../../../commonTypes/ClientTypes'
import { CreateCreditParams } from '../../../commonTypes/CreditTypes'
import { CreateInvoiceParams, Invoice } from '../../../commonTypes/InvoiceTypes'
import { getClientSummary } from '../api/clients'
import { createCredit, deleteCredit } from '../api/credits'
import { createInvoice, deleteInvoice, syncInvoice, updateInvoiceWarning } from '../api/invoices'
import useOrganization from './useOrganization'

const Context = createContext<{
	client?: DetailedClientSummary,
	isLoading: boolean,
	addCredit(params: CreateCreditParams): Promise<void>,
	addInvoice(params: Omit<CreateInvoiceParams, 'organization'>): Promise<Invoice>
	removeCredit(id:string):Promise<void>,
	removeInvoice(id:string):Promise<void>
	setInvoiceWarning(invoiceId: string, warningId: string, ignored: boolean): Promise<void>,
	syncClientInvoice(stripeId:string):Promise<void>,
}>(null as any)


interface ProviderProps{
	children:any,
	clientId: string,
}

export const SingleClientProvider = ({ children, clientId }: ProviderProps) => {
	const [client, setClient] = useState<DetailedClientSummary>()
	const [isLoading, setIsLoading] = useState(true)
	const { organization } = useOrganization()
	
	useEffect(() => {
		setIsLoading(true)
		getClientSummary({
			clientId,
		}).then(newClient => {
			setClient(newClient)
			setIsLoading(false)
		}).catch(() => setIsLoading(false))
	}, [clientId])
	
	const addCredit = useCallback(async (params: CreateCreditParams) => {
		await createCredit(clientId, params)
		getClientSummary({ clientId }).then(setClient)
	}, [clientId])
	
	const removeCredit = useCallback(async (id: string) => {
		await deleteCredit(id)
		getClientSummary({ clientId }).then(setClient)
	}, [clientId])
	
	const addInvoice = useCallback(async (params: Omit<CreateInvoiceParams, 'organization'>) => {
		const newInvoice = await createInvoice({
			...params,
			organization: organization._id,
		})
		
		getClientSummary({ clientId }).then(setClient)
		return newInvoice
	}, [clientId])
	
	const removeInvoice = useCallback(async (id:string) => {
		await deleteInvoice(id)
		getClientSummary({ clientId }).then(setClient)
	}, [clientId])
	
	const setInvoiceWarning = useCallback(async (invoiceId: string, warningId: string, ignored:boolean) => {
		await updateInvoiceWarning({
			ignored,
			invoiceId,
			warningId,
		})
		
		getClientSummary({ clientId }).then(setClient)
	}, [])
	
	const syncClientInvoice = useCallback(async (stripeId:string) => {
		await syncInvoice(stripeId)
		await getClientSummary({
			clientId,
		}).then(setClient)
	}, [organization._id])

	return <Context.Provider value={{
		client,
		isLoading,
		addCredit,
		removeCredit,
		removeInvoice,
		addInvoice,
		setInvoiceWarning,
		syncClientInvoice,
	}}>
		{ children }
	</Context.Provider>
}

const useSingleClient = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useSingleClient outside provider!')
	}
	return val
}

export default useSingleClient
