import React, { useState } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import useClients from '../../data-hooks/useClients'

export default function CreateClientMenu() {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		defaultRate: 0,
	})
	
	const [message, setMessage] = useState('')

	const { create } = useClients()

	const [loading, setLoading] = useState(false)

	function changeHandler(e: any) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}

	const createHandler = async () => {
		if (formData.name && formData.email) {
			setLoading(true)
			await create({
				...formData,
				defaultRate: formData.defaultRate * 100,
			}).catch(() => {
				setLoading(false)
			})
			setLoading(false)
			setFormData({
				name: '',
				email: '',
				defaultRate: 0,
			})
		} else {
			setMessage('Please fill all required fields!')
		}
	}

	return (
		<div className='CreateChargeMenu formMenu'>
			<div className="story">
				<div className='story' style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}>
					{message}
				</div>
				
				<label>Name</label>
				<input
					className="input wide"
					name='name'
					value={formData.name}
					onChange={changeHandler}
				/>
			</div>
			
			<div className="story">
				<label>Email</label>
				<input
					className="input wide"
					name='email'
					value={formData.email}
					onChange={changeHandler}
				/>
			</div>
			
			<div className="story">
				<label>Hourly Rate ($/hour)</label>
				<input
					className="input wide"
					name='defaultRate'
					type='number'
					value={formData.defaultRate}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<FreeButton
					className="button positive"
					style={{ width: '100%' }}
					onClick={createHandler}
					loading={loading}
				>
					Create
				</FreeButton>
			</div>

		</div>
	)
}
