import React, { useState } from 'react'
import { faExclamationTriangle, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Page, QuickTable, QuickTableCellProps, QuickTableHeaders } from 'react-ui-scaffold'
import AsyncButton from '../../components/AsyncButton'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useBankTransactions, { BankTransactionsProvider } from '../../data-hooks/useBankTransactions'
import wrapWithProvider from '../../utils/wrapWithProvider'
import CurrencyCell from '../../components/QuickTableCells/CurrencyCell'
import DateCell from '../../components/QuickTableCells/DateCell'
import MarkAsResolvedCell from './MarkAsResolvedCell'
import AddExpenseCell from './AddExpenseCell'
import { BankTransaction } from '../../../../commonTypes/BankingTypes'
import { AllChargeCodesProvider } from '../../data-hooks/useAllChargeCodes'
import { Link } from 'react-router-dom'
import { BANK_CONNECTIONS_PATH } from '../../paths'

const rowStyler = (row:BankTransaction) => ({
	color: row.resolved ? 'gray' : '',
	opacity: row.resolved ? '30%' : '',
})

const headers: QuickTableHeaders = {
	name: { title: 'Name', width: 300 },
	amount: { title: 'Amount', component: CurrencyCell, width: 200, headerStyle: { textAlign: 'center' } },
	merchantName: { title: 'Merchant', width: 200 },
	date: { title: 'Date', component: DateCell },
	resolve: { title: '', component: MarkAsResolvedCell, width: 40 },
	addExpense: { title: '', component: AddExpenseCell },
	parent: { title: 'Parent', component: ({ cell }:QuickTableCellProps) => <>{cell?.notes}</>, width: 200 },
}

function BankTransactionsPage() {
	const { isLoading, bankTransactions, refresh } = useBankTransactions()
	
	const [balanceError, setBalanceError] = useState(false)
	
	const refreshHandler = () => refresh()
		.then(() => setBalanceError(false))
		.catch(() => setBalanceError(true))

	return (
		<div className="flex">
			<Sidebar />
			<NotSidebar>
				<NavBar title='Bank Transactions'>
					<AsyncButton className="button neutral plain" onClick={refreshHandler}>
						<FontAwesomeIcon icon={faSync}/>
					</AsyncButton>
					<Link className="button plain neutral bold" to={BANK_CONNECTIONS_PATH}>Manage Banks</Link>
				</NavBar>

				<Page loading={isLoading}>
					<AllChargeCodesProvider>
						{balanceError && <div style={{ padding: '10px' }}>
							<Link className='button negative bold plain' to={BANK_CONNECTIONS_PATH}>
								<FontAwesomeIcon icon={faExclamationTriangle} /> Error fetching balance
							</Link>
						</div>}
						
						<QuickTable
							headers={headers}
							data={bankTransactions}
							primaryKey={'_id'}
							hover
							rowStyle={rowStyler}
						/>
					</AllChargeCodesProvider>
				</Page>
			</NotSidebar>
		</div>
	)
}

export default wrapWithProvider(BankTransactionsPage, BankTransactionsProvider)
