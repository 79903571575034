import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { MenuButton, QuickTableCellProps } from 'react-ui-scaffold'
import { BankTransaction } from '../../../../commonTypes/BankingTypes'
import { CreateExpenseParams } from '../../../../commonTypes/ExpenseTypes'
import CreateExpenseMenu from '../../components/CreateExpenseMenu'
import useBankTransactions from '../../data-hooks/useBankTransactions'
import { getReportingMonth } from '../../utils/dateUtils'

export default function AddExpenseCell({ row }:QuickTableCellProps) {
	const transaction = row as BankTransaction
	const { addExpense } = useBankTransactions()
	
	if (transaction.resolved) {
		return null
	}
	
	const createHandler = (c: CreateExpenseParams) => addExpense(transaction._id, c)
	
	return (
		<MenuButton
			title='Create Expense'
			className='plain'
			menuContent={CreateExpenseMenu}
			style={{ color: 'gray' }}
			permanent
			menuProps={{
				create: createHandler,
				prefillFields: {
					amount: transaction.amount/100,
					notes: transaction.name,
					amountChargedToClient: transaction.amount / 100,
					reportingMonth: getReportingMonth(new Date(transaction.date)),
				},
			}}
		>
			<FontAwesomeIcon icon={faPlus} />
		</MenuButton>
	)
}
