import React from 'react'
import './NavBar.css'
import { faBars, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MenuButton } from 'react-ui-scaffold'
import UserMenu from './UserMenu'
import useSidebarVisible from '../Sidebar/useSidebarVisible'

interface Props{
	children?: any,
	title?: string
}

export default function NavBar({ children, title }:Props) {
	const { setVisible } = useSidebarVisible()
	
	return (
		<div className='NavBar'>
			
			<div className="navContainer flexY">
				<div className="titleBox">
					<button
						className="button noDesktop noTablet plain hamburger"
						onClick={() => setVisible(true)}
					>
						<FontAwesomeIcon icon={faBars}/>
					</button>
					
					{title}
				</div>

				<div className="childrenBox">
					{children}
				</div>

				<div className="controls">
					<MenuButton menuContent={UserMenu} menuStyle={{ maxWidth: 'fit-content' }}>
						<span className="icon">
							<FontAwesomeIcon icon={faUserCircle} />
						</span>
					</MenuButton>
				</div>
			</div>
			
		</div>
	)
}
