import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useOrganization from '../../data-hooks/useOrganization'

interface Props {
	children: any,
	className?: string,
	to?: string,
	onClick?(): any,
	activeLink?: string,
	admin?: boolean
}

export default function SideButton({
	children,
	className,
	to,
	onClick = () => { },
	activeLink,
	admin,
}:Props){

	const activeClass = useMemo(() => activeLink?.startsWith(`${to}`) ? ' active ' : '', [activeLink, to])
	
	const { accessLevel } = useOrganization()
	
	if (admin && accessLevel !== 'admin'){
		return null
	}

	if (to) {
		return (
			<Link to={to}>
				<div className={'SideButton ' + className + activeClass} onClick={onClick}>
					<div className="textBox">
						{children}
					</div>
				</div>
			</Link>

		)
	}

	return (
		<div className={'SideButton ' + className + activeClass} onClick={onClick}>
			<div className="textBox">
				{children}
			</div>
		</div>
	)
}
