import React from 'react'
import { QuickTableCellProps } from 'react-ui-scaffold'
import { ChargeCodeSummary } from '../../../../../../commonTypes/ChargeCodeTypes'

export default function AmountInput({ index, amountByChargeCode, editChargeCodeAmount }:QuickTableCellProps<ChargeCodeSummary>) {
	return (
		<input
			className='input wide'
			type='number'
			value={amountByChargeCode[index].amount}
			onChange={(e) => editChargeCodeAmount(e.target.value, index)}
		/>
	)
}
