import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { PageMessageContents, Page, FreeButton } from 'react-ui-scaffold'
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth'
import './Login.css'
import cionLogo from '../../assets/logo-black.svg'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import useAuth from '../../data-hooks/useAuth'
import { MAIN_PATH } from '../../paths'

export default function Login() {
	const { user, error } = useAuth()
	const [message, setMessage] = useState<PageMessageContents>()
	const [isLoading, setIsLoading] = useState(false)

	const login = async () => {
		setIsLoading(true)
		const provider = new GoogleAuthProvider()
		signInWithPopup(getAuth(), provider).catch(() => setMessage({ content: 'Unable to sign in!' }))
		setIsLoading(false)
	}

	useEffect(() => {
		if (error?.error) {
			setMessage({
				content: error.error,
				sticky: true,
				color: 'var(--negativeAccentColor)',
				icon: faExclamationCircle,
			})
		} else {
			setMessage(undefined)
		}
	}, [error])

	if (user) {
		return <Redirect to={MAIN_PATH} />
	}

	return (
		<Page message={message} className='Login'>
			<div className="flexCenter loginBody">
				<div className="loginCard">
					<div className="logo">
						<img src={cionLogo} />
					</div>

					<div className="layer">
						<FreeButton loading={isLoading} className="button wide cion" onClick={login}>Sign in with Google</FreeButton>
					</div>
				</div>
			</div>
		</Page>
	)
}
