import React from 'react'
import { MenuButton, Page } from 'react-ui-scaffold'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import useEmployees, { EmployeesProvider } from '../../data-hooks/useEmployees'
import AddEmployeeMenu from './AddEmployeeMenu'
import EmployeesTable from './EmployeesTable'

function InnerEmployeesPage() {
	
	const { employees, isLoading, remove } = useEmployees()

	return (
		<Page className='Employees flex'>
			<Sidebar />
			<NotSidebar>
				<NavBar title='Employees'>
					<MenuButton
						menuContent={AddEmployeeMenu}
						className='button neutral'
						permanent
					>Add Employee</MenuButton>
				</NavBar>
				
				<EmployeesTable
					employees={employees}
					loading={isLoading}
					onDelete={remove}
				/>
			</NotSidebar>
		</Page>
	)
}

export default function EmployeesPage(){
	return <EmployeesProvider>
		<InnerEmployeesPage/>
	</EmployeesProvider>
}
