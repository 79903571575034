import React from 'react'

interface Props{
	children: any,
	className?: string
}

export default function NotSidebar({ children, className }:Props) {
	return (
		<div className={`NotSidebar ${className}`}>
			{children}
		</div>
	)
}
