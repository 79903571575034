export function formatCurrency(amount: number) {
	let num = Math.round(amount) / 100
	
	if (isNaN(num)) {
		return ' - '
	}
	
	return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)
}

interface QueryStringParams {
	[name: string]: string | undefined
}

export function formatQueryString(params?: QueryStringParams) {
	let q = '?'
	
	if (!params){
		return ''
	}

	Object.entries(params).forEach(([key, value]) => {
		if (value) {
			q = `${q}&${key}=${value}`
		}
	})

	return q
}
