import React from 'react'
import { MenuButton, Page } from 'react-ui-scaffold'
import ChargeTable from './ChargeTable/TimesheetChargeTable'
import NavBar from '../../components/NavBar/NavBar'
import NotSidebar from '../../components/Sidebar/NotSidebar'
import Sidebar from '../../components/Sidebar/Sidebar'
import { MyChargeCodesProvider } from '../../data-hooks/useMyChargeCodes'
import useMyCharges, { MyChargesProvider } from '../../data-hooks/useMyCharges'
import CreateChargeMenu from './CreateChargeMenu'

function InnerTimesheet() {
	const { charges, isLoading, del } = useMyCharges()
	
	return (
		<Page className='Timesheet flex'>
			<Sidebar/>
			<NotSidebar>
				<MyChargeCodesProvider>
					<NavBar title='Timesheet'>
						<MenuButton
							menuContent={CreateChargeMenu}
							className='button neutral'
							permanent
						>Create Charge</MenuButton>
					</NavBar>
					<ChargeTable
						charges={charges}
						loading={isLoading}
						onDelete={del}
					/>
				</MyChargeCodesProvider>
			</NotSidebar>
		</Page>
	)
}

export default function Timesheet(){
	return <MyChargesProvider>
		<InnerTimesheet/>
	</MyChargesProvider>
}
