import React, { useEffect, useState } from 'react'
import { Loading } from 'react-ui-scaffold'
import { BankConnectionSummary, PlaidBankAccount } from '../../../../../commonTypes/BankingTypes'
import { getPlaidAccountsForBankConnection } from '../../../api/bankConnections'
import useBankConnections from '../../../data-hooks/useBankConnections'
import BankAccountOption from './BankAccountOption'
import './ConnectBankAccountsMenu.css'

interface Props{
	bankConnection: BankConnectionSummary
	onClose(): any,
}

export default function ConnectBankAccountsMenu({ bankConnection, onClose }:Props) {
	const [options, setOptions] = useState<PlaidBankAccount[]>([])
	const [isLoading, setIsLoading] = useState(true)
	
	useEffect(() => {
		setIsLoading(true)
		getPlaidAccountsForBankConnection(bankConnection._id).then(newOptions => {
			setIsLoading(false)
			setOptions(newOptions)
		})
	}, [bankConnection._id])
	
	const { addAccounts } = useBankConnections()
	
	const connectAccountHandler = async (bankConnectionId: string, plaidAccountIds: string[]) => {
		await addAccounts(bankConnectionId, plaidAccountIds)
	}
	
	return (
		<div className='ConnectBankAccountsMenu'>
			<div className="story">
				Choose accounts to connect:
			</div>
			
			{isLoading && <div className="story flexCenter">
				<Loading/>
			</div>}
			
			{!isLoading && <>
				<div className="story">
					{options.map(op => <BankAccountOption
						option={op}
						key={op.account_id}
						bankConnectionId={bankConnection._id}
						connectedAccounts={bankConnection.bankAccounts}
						onConnect={connectAccountHandler}
					/>)}
				</div>
				
				<div className="story">
					<button className="button wide" onClick={onClose}>Done</button>
				</div>
			</>}
		</div>
	)
}
