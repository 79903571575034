import React, { useState } from 'react'
import Popup from '../../Popup/Popup'
import useAllChargeCodes from '../../../data-hooks/useAllChargeCodes'
import useOrganization from '../../../data-hooks/useOrganization'
import { ChargeCode, ChargeCodeMember } from '../../../../../commonTypes/ChargeCodeTypes'

interface Props{
	visible?:boolean,
	setVisible(v:boolean):void,
	chargeCode: ChargeCode
}

export default function AddMemberPopup({ visible, setVisible, chargeCode }:Props) {
	
	const { organization } = useOrganization()
	const { addMembers } = useAllChargeCodes()

	const [data, setData] = useState<Partial<ChargeCodeMember>>({
		data: '',
		// rateIn: 0,
		// rateOut: 0,
	})
	
	const [message, setMessage] = useState('')
	
	const changeHandler = (e:any) => setData({
		...data,
		[e.target.name]: e.target.value,
	})
	
	const submit = async () => await addMembers(chargeCode._id, [{
		...data,
		// rateIn: (data.rateIn as number) * 100,
		// rateOut: (data.rateOut as number) * 100,
	}]).then(() => {
		setVisible(false)
	}).catch(({ error }) => {
		setMessage(error)
	})


	return (
		<Popup
			className='AddMemberPopup'
			visible={visible}
			setVisible={setVisible}
			title={<>Add member to <span className='highlight'>{chargeCode.name}</span></>}
		>
			<div className="story message">
				{message}
			</div>
			
			<div className="story">
				<div className='name'>Member</div>
				
				<select
					className="input wide"
					name='data'
					value={data.data}
					onChange={changeHandler}
				>
					<option value=''></option>
					{organization.members.map(m => <option key={m.user._id} value={m.user._id}>
						{`${m.user.firstName} ${m.user.lastName}`}
					</option>)}
				</select>
			</div>
			
			{/* <div className="story">
				<div className='name'>Rate paid</div>
				<input
					type='number'
					className="input wide"
					name='rateIn'
					value={data.rateIn}
					onChange={changeHandler}
				/>
			</div> */}
			
			{/* {chargeCode.client && <div className="story">
				<div className='name'>Rate charged to client</div>
				<input
					type='number'
					className="input wide"
					name='rateOut'
					value={data.rateOut}
					onChange={changeHandler}
				/>
			</div>} */}
			
			<div className="story">
				<button className="button wide" onClick={submit}>Add</button>
			</div>
		</Popup>
	)
}
