import { CreateCreditParams, Credit } from '../../../commonTypes/CreditTypes'
import beam from './beam'

export const createCredit = (clientId:string, params: CreateCreditParams) => beam.post<Credit>(`/api/clients/${clientId}/credits`, params)

export const deleteCredit = (creditId:string) => beam.del(`/api/credits/${creditId}`)

export const getCreditsByClient = (clientId: string) => beam.get<Credit[]>(`/api/clients/${clientId}/credits`)

export const getCreditsByOrganization = (orgId: string) => beam.get<Credit[]>(`/api/organizations/${orgId}/credits`)
